// Init
// This component will run if user requests someone else profile
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Pages
import Profile from "../containers/Profile.jsx";

// Component
function UsersProfile({ users, orders, role, handleReload }) {
	// Initializing Hooks
	let { userNo } = useParams();
	let [user, setUser] = useState({});

	// Filtering users with params
	useEffect(() => {
		for (let i = 0; i < users.length; i++) {
			if (users[i].uid == userNo) {
				setUser(users[i]);
				break;
			}
		}
	}, [users]);

	// filtering orders according user
	const filteredOrders = orders.filter((item) => {
		if (user.role === "manager") {
			return item.seller_no === user.uid;
		} else if (user.role === "user") {
			return item.user_no === user.uid;
		}
	});

	// Rendering
	return (
		<Profile
			user={user}
			orders={user.role === "admin" ? orders : filteredOrders}
			handleReload={handleReload}
			restricted={role === "admin" ? false : true}
			role={role}
		/>
	);
}

// Export
export default UsersProfile;
