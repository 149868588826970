// Init
import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";

// Component
function ProductsPagination({
	setData,
	status,
	reload,
	setProductsLength,
	category,
}) {
	// Getting Query Data
	let { pathname, search } = useLocation();
	let query = queryString.parse(search);
	let page = parseInt(query.page) - 1 || 0;

	let history = useHistory();

	// Initializing States
	const [perPage] = useState(20);
	const [pageCount, setPageCount] = useState(0);

	// Using Effect
	useEffect(() => {
		getData();

		// Scrolling to top everytime when page changes
		window.scrollTo(0, 0);
	}, [page, category, status, reload]);

	// Handle Page Click
	const handlePageClick = (e) => {
		const selectedPage = e.selected;
		history.push(`${pathname}?page=${selectedPage + 1}`);
	};

	// Get data function
	const getData = () => {
		axios
			.get(
				`/products?page=${page + 1}&limit=${perPage}&status=${status}`,
				{ params: { category } }
			)
			.then((products) => {
				setData(products.data.docs);
				setPageCount(products.data.pages);
				setProductsLength(products.data.total);
			})
			.catch((err) => {
				// If session expired then redirect lo login page
				if (err.response.status === 401) {
					window.location = "/login";
				}

				console.log({ ...err });
			});
	};

	// Render
	return (
		<ReactPaginate
			previousLabel={"<"}
			nextLabel={">"}
			breakLabel={"..."}
			breakClassName={"break-me"}
			pageCount={pageCount}
			marginPagesDisplayed={2}
			pageRangeDisplayed={5}
			onPageChange={handlePageClick}
			containerClassName={"pagination"}
			subContainerClassName={"pages pagination"}
			activeClassName={"active"}
			forcePage={page}
		/>
	);
}

// Export
export default ProductsPagination;
