// Init
import axios from "axios";
import { analytics } from "../firebase";
import { toast } from "react-toastify";

// Handle Input
const handleInput = (e, setUser) => {
  setUser((prev) => {
    return {
      ...prev,
      [e.target.name]: e.target.value,
    };
  });
};

// Handle Submit
const handleSubmit = (e, user, setUser, setLoading) => {
  e.preventDefault();
  const { password, confirmPassword, gender, role } = user;

  if (password !== confirmPassword) {
    // if password not matched
    toast.error("Password does not matched!");
  } else if (password.length < 8) {
    // if password weak
    toast.error("Password must be at least 8 characters!");
  } else {
    // if all ok
    setLoading(true);

    // creating user in firebase
    axios
      .post("/signup", user)
      .then(() => {
        // if all done successfully
        setLoading(false);
        toast.warn("Account is submitted for approval!");
        setUser({
          name: "",
          cnic: "",
          email: "",
          password: "",
          confirmPassword: "",
          role: role,
          gender: gender,
          address: "",
          city: "",
          country: "",
          number: "",
          bankName: "",
          bankAccount: "",
          referral_name: "",
          referral_number: "",
        });

        // Logging analytics
        analytics.logEvent("Sign Up");
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data);
      });
  }
};

// Export
export { handleInput, handleSubmit };
