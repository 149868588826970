// Init
import React, { useEffect } from 'react';

// Component
function ErrorPage() {
  // Changing the title of page
  useEffect(() => {
    document.title = '404 - SPMWeb';
  }, []);

  // Render
  return (
    <section className="error-page">
      <span>404</span>
      <span>Not Found</span>
    </section>
  );
}

// export
export default ErrorPage;
