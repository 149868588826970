import React, { useState, useEffect } from 'react';

// Utils
import { handleInput, handleSubmit, handleDelete } from '../utils/accounts';

function Accounts({ accounts, handleReload }) {
  // Initializing States
  let [creating, setCreating] = useState(false);
  let [account, setAccount] = useState({});

  // Reloading component when it loads
  useEffect(() => {
    handleReload('accounts');
    // Changing the title of page
    document.title = 'Accounts - SPMWeb';
  }, []);

  return (
    // Main
    <section className="main">
      {/* Main Heading */}
      <h2>Accounts</h2>

      {/* Main Contianer */}
      <div className="main-container">
        {/* Head */}
        <div className="main-container-head">
          <div>
            <h3>Active Accounts</h3>
            &nbsp;-&nbsp;
            <span>(Total {accounts.length})</span>
          </div>
          <button
            className="green-btn issue-btn"
            style={{ marginRight: '55px' }}
            onClick={() => setCreating(!creating)}
          >
            {creating ? 'Cancel' : 'Add New Account'}
          </button>
        </div>

        {/* Create account form */}
        {creating ? (
          <form
            className="form-container"
            style={{
              padding: '20px 10px',
              borderBottom: '1px solid #e1e5eb',
            }}
            onSubmit={(e) =>
              handleSubmit(e, account, setAccount, setCreating, handleReload)
            }
          >
            <section>
              <input
                type="text"
                name="platform"
                placeholder="Platform *"
                value={account.platform}
                onChange={(e) => handleInput(e, setAccount)}
                required
              />
              <input
                type="text"
                name="account_no"
                placeholder="Account Number"
                value={account.account_no}
                onChange={(e) => handleInput(e, setAccount)}
              />
              <input
                type="text"
                name="title"
                placeholder="Account Title *"
                value={account.title}
                onChange={(e) => handleInput(e, setAccount)}
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Account Email *"
                value={account.email}
                onChange={(e) => handleInput(e, setAccount)}
                required
              />
              <button type="submit" className="form-submit-btn">
                Add Now
              </button>
            </section>
          </form>
        ) : null}

        {/* Body */}
        <div className="table-container">
          <table className="main-table">
            {/* Table Head */}
            <thead>
              <tr>
                <th>AC Number</th>
                <th>Platform</th>
                <th>Title</th>
                <th>Email</th>
                <th>Creation Date</th>
                <th></th>
                <th></th>
              </tr>
            </thead>

            {/* Table Body */}
            <tbody>
              {accounts.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.account_no}</td>
                    <td>{item.platform}</td>
                    <td>{item.title}</td>
                    <td>{item.email}</td>
                    <td>{item.dateCreated}</td>

                    {/* Button */}
                    <td>
                      <button
                        className="del-btn"
                        onClick={() => handleDelete(item._id, handleReload)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}

export default Accounts;
