// Init
import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";

// utils
import logout from "../api/auth/logout";

// Header
import Header from "./Header";

// Logo
import logo from "../assets/icon.png";

// Component
function Sidebar({ role, user, messages }) {
	// Initializing State
	let [toggle, setToggle] = useState(false);
	let { pathname } = useLocation();

	// Setting toggle to false in mobile
	useEffect(() => {
		setToggle(false);
	}, [pathname]);

	// Dropdown functionality
	useEffect(() => {
		var dropdown = document.getElementsByClassName("dropdown-btn");
		var i;

		for (i = 0; i < dropdown.length; i++) {
			dropdown[i].addEventListener("click", function () {
				var dropdownContent = this.nextElementSibling;
				if (dropdownContent.style.display === "block") {
					dropdownContent.style.display = "none";
				} else {
					dropdownContent.style.display = "block";
				}
			});
		}
	}, []);

	// Rendering Component
	return (
		<>
			<Header user={user} messages={messages} setToggle={setToggle} />
			<aside id={toggle ? "sidebar" : "null"}>
				{/* Head */}
				<div className="aside-head">
					<NavLink exact to="/dashboard">
						<img src={logo} alt="Logo" />
						<span>Dashboard</span>
					</NavLink>
					<i
						className="fas fa-arrow-left toggle-sidebar"
						onClick={() => setToggle(false)}
					></i>
				</div>

				{/* Links */}
				<nav>
					<div className="aside-item">
						{/* Dashboard */}
						<NavLink
							exact
							to="/dashboard"
							activeClassName="nav-active"
						>
							<button>
								<i className="fas fa-chart-bar"></i>
								<span>Dashboard</span>
							</button>
						</NavLink>

						{/* Orders */}
						<button className="dropdown-btn">
							<i className="fas fa-shopping-basket"></i>
							<span>Orders</span>
						</button>
						{/* Dropdown List */}
						<div className="dropdown-container">
							<NavLink
								exact
								to="/all_orders"
								activeClassName="dropdown-active"
							>
								<i className="fas fa-eye"></i>
								<span>All</span>
							</NavLink>
							<NavLink
								exact
								to="/orders"
								activeClassName="dropdown-active"
							>
								<i className="fas fa-eye"></i>
								<span>Ordered</span>
							</NavLink>
							<NavLink
								exact
								to="/review"
								activeClassName="dropdown-active"
							>
								<i className="fas fa-eye"></i>
								<span>Reviewed</span>
							</NavLink>
							<NavLink
								exact
								to="/delivered"
								activeClassName="dropdown-active"
							>
								<i className="fas fa-thumbs-up"></i>
								<span>Pending Refund</span>
							</NavLink>
							<NavLink
								exact
								to="/review_deleted"
								activeClassName="dropdown-active"
							>
								<i className="fas fa-times-circle"></i>
								<span>Review Deleted</span>
							</NavLink>
							<NavLink
								exact
								to="/refunded"
								activeClassName="dropdown-active"
							>
								<i className="fas fa-minus-square"></i>
								<span>Refunded</span>
							</NavLink>
							<NavLink
								exact
								to="/onhold"
								activeClassName="dropdown-active"
							>
								<i className="fas fa-hand-paper"></i>
								<span>On Hold</span>
							</NavLink>
							<NavLink
								exact
								to="/pending"
								activeClassName="dropdown-active"
							>
								<i className="fas fa-clock"></i>
								<span>Refunded Pending Review</span>
							</NavLink>
							<NavLink
								exact
								to="/cancelled"
								activeClassName="dropdown-active"
							>
								<i className="fas fa-times-circle"></i>
								<span>Cancelled</span>
							</NavLink>
							<NavLink
								exact
								to="/commissioned"
								activeClassName="dropdown-active"
							>
								<i className="fas fa-credit-card"></i>
								<span>Commissioned</span>
							</NavLink>
							<NavLink
								exact
								to="/completed"
								activeClassName="dropdown-active"
							>
								<i className="fas fa-check-circle"></i>
								<span>Completed</span>
							</NavLink>
						</div>

						{/* Products */}

						<button className="dropdown-btn">
							<i className="fas fa-sticky-note"></i>
							<span>Products</span>
						</button>
						{/* Dropdown List */}
						<div className="dropdown-container">
							{role === "admin" || role === "manager" ? (
								<NavLink
									exact
									to="/add_product"
									activeClassName="dropdown-active"
								>
									<i className="fas fa-plus-square"></i>
									<span>Add New</span>
								</NavLink>
							) : null}
							<NavLink
								exact
								to="/products"
								activeClassName="dropdown-active"
							>
								<i className="fas fa-eye"></i>
								<span>All Products</span>
							</NavLink>
							{role === "admin" || role === "manager" ? (
								<NavLink
									exact
									to="/disabled_products"
									activeClassName="dropdown-active"
								>
									<i className="fas fa-eye"></i>
									<span>Disabled</span>
								</NavLink>
							) : null}
							<NavLink
								exact
								to="/products/General"
								activeClassName="dropdown-active"
							>
								<i className="fas fa-store-alt"></i>
								<span>General</span>
							</NavLink>
							<NavLink
								exact
								to="/products/Kindle_&_Books"
								activeClassName="dropdown-active"
							>
								<i className="fas fa-book"></i>
								<span>Kindle & Books</span>
							</NavLink>
							<NavLink
								exact
								to="/products/Electronics"
								activeClassName="dropdown-active"
							>
								<i className="fab fa-usb"></i>
								<span>Electronics</span>
							</NavLink>
							<NavLink
								exact
								to="/products/Health_&_Beauty"
								activeClassName="dropdown-active"
							>
								<i className="fas fa-heartbeat"></i>
								<span>Health & Beauty</span>
							</NavLink>
							<NavLink
								exact
								to="/products/Baby_Products"
								activeClassName="dropdown-active"
							>
								<i className="fas fa-baby"></i>
								<span>Baby Products</span>
							</NavLink>
							<NavLink
								exact
								to="/products/Gaming_Devices"
								activeClassName="dropdown-active"
							>
								<i className="fas fa-table-tennis"></i>
								<span>Gaming Devices</span>
							</NavLink>
							<NavLink
								exact
								to="/products/Fashion"
								activeClassName="dropdown-active"
							>
								<i className="fas fa-tshirt"></i>
								<span>Fashion (Clothes & Shoes)</span>
							</NavLink>
							<NavLink
								exact
								to="/products/Electronic_Accessories"
								activeClassName="dropdown-active"
							>
								<i className="fas fa-mobile-alt"></i>
								<span>Electronic Accessories</span>
							</NavLink>
							<NavLink
								exact
								to="/products/Automotive_Accessories"
								activeClassName="dropdown-active"
							>
								<i className="fas fa-car-side"></i>
								<span>Automotive Accessories</span>
							</NavLink>
							<NavLink
								exact
								to="/products/Expensive_Products"
								activeClassName="dropdown-active"
							>
								<i className="fas fa-store-alt"></i>
								<span>Expensive Products</span>
							</NavLink>
							<NavLink
								exact
								to="/products/Pet_Related"
								activeClassName="dropdown-active"
							>
								<i className="fas fa-paw"></i>
								<span>Pet Related</span>
							</NavLink>
							<NavLink
								exact
								to="/products/Home_&_Kitchen"
								activeClassName="dropdown-active"
							>
								<i className="fas fa-home"></i>
								<span>Home & Kitchen</span>
							</NavLink>
						</div>

						{/* Reservations */}
						<NavLink
							exact
							to="/reservations"
							activeClassName="nav-active"
						>
							<button>
								<i className="fas fa-money-check-alt"></i>
								<span>Reservations</span>
							</button>
						</NavLink>

						{/* Chnage Password */}
						<NavLink
							exact
							to="/user/password"
							activeClassName="nav-active"
						>
							<button>
								<i className="fas fa-lock"></i>
								<span>Change Password</span>
							</button>
						</NavLink>

						{/* Profile */}
						<NavLink
							exact
							to="/profile"
							activeClassName="nav-active"
						>
							<button>
								<i className="fas fa-user"></i>
								<span>User Profile</span>
							</button>
						</NavLink>

						{/* Users */}
						{role === "admin" ? (
							<>
								<button className="dropdown-btn">
									<i className="fas fa-users"></i>
									<span>Users</span>
								</button>
								{/* Dropdown List */}
								<div className="dropdown-container">
									<NavLink
										exact
										to="/add-user"
										activeClassName="dropdown-active"
									>
										<i className="fas fa-user-plus"></i>
										<span>Add User</span>
									</NavLink>
									<NavLink
										exact
										to="/users"
										activeClassName="dropdown-active"
									>
										<i className="fas fa-users"></i>
										<span>All Users</span>
									</NavLink>
									<NavLink
										exact
										to="/disabled_users"
										activeClassName="dropdown-active"
									>
										<i className="fas fa-users"></i>
										<span>Disabled</span>
									</NavLink>
									<NavLink
										exact
										to="/pending_users"
										activeClassName="dropdown-active"
									>
										<i className="fas fa-users"></i>
										<span>Pending</span>
									</NavLink>
								</div>
							</>
						) : null}

						{/* Payments */}
						{role !== "user" ? (
							<>
								<button className="dropdown-btn">
									<i className="fas fa-wallet"></i>
									<span>Payments</span>
								</button>
								{/* Dropdown List */}
								<div className="dropdown-container">
									{role === "manager" ? (
										<NavLink
											exact
											to="/create_invoice"
											activeClassName="dropdown-active"
										>
											<i className="fas fa-plus-square"></i>
											<span>Create Invoice</span>
										</NavLink>
									) : null}
									<NavLink
										exact
										to="/invoices"
										activeClassName="dropdown-active"
									>
										<i className="fas fa-file-invoice-dollar"></i>
										<span>Pending Invoices</span>
									</NavLink>
									<NavLink
										exact
										to="/paid_invoices"
										activeClassName="dropdown-active"
									>
										<i className="fas fa-file-invoice-dollar"></i>
										<span>Paid Invoices</span>
									</NavLink>
									{role === "admin" ? (
										<NavLink
											exact
											to="/payment_accounts"
											activeClassName="dropdown-active"
										>
											<i className="fab fa-alipay"></i>
											<span>Accounts</span>
										</NavLink>
									) : null}
								</div>
							</>
						) : null}

						{/* Excel */}
						{role === "user" ? null : (
							<NavLink
								exact
								to="/excel"
								activeClassName="nav-active"
							>
								<button>
									<i className="fas fa-file-excel"></i>
									<span>Create Excel</span>
								</button>
							</NavLink>
						)}

						{/* Support */}
						<button className="dropdown-btn">
							<i className="fas fa-envelope"></i>
							<span>Support</span>
						</button>
						{/* Dropdown List */}
						<div className="dropdown-container">
							<NavLink
								exact
								to="/support"
								activeClassName="dropdown-active"
							>
								<i className="fas fa-eye"></i>
								<span>Active</span>
							</NavLink>
							<NavLink
								exact
								to="/support/resolved"
								activeClassName="dropdown-active"
							>
								<i className="fas fa-check-circle"></i>
								<span>Resolved</span>
							</NavLink>
						</div>

						{/* Logout */}
						<button onClick={logout}>
							<i className="fas fa-sign-out-alt"></i>
							<span>Logout</span>
						</button>
					</div>
				</nav>
			</aside>
		</>
	);
}

// Export
export default Sidebar;
