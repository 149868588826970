// Init
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import MarketDropdown from '../components/MarketDropdown';

// Controller
import {
  handleInput,
  handleBulkUpload,
  handleSubmit,
} from '../utils/addProduct';

// Component
function AddProduct() {
  // Initializing States
  let [product, setProduct] = useState({ market: '', category: 'General' });
  let [loading, setLoading] = useState(false);

  // Image Upload Progress
  let [progress, setProgress] = useState({ amazon_pic: 0, pic: 0 });

  // Changing the title of page
  useEffect(() => {
    document.title = 'Add Product - SPMWeb';
  }, []);

  // Setting Inputs default value from last product
  useEffect(() => {
    axios
      .get(`/products?page=1&limit=1&status=true`)
      .then((res) => {
        let oldProduct = res.data.docs.pop();
        setProduct((prev) => {
          return {
            ...prev,
            portal_commission: oldProduct?.portal_commission,
            instructions: oldProduct?.instructions,
            refund_conditions: oldProduct?.refund_conditions,
            commission_conditions: oldProduct?.commission_conditions,
          };
        });
      })
      .catch((err) => {
        // If session expired then redirect lo login page
        if (err?.response?.status === 401) {
          window.location = '/login';
        }
        console.log({ ...err });
      });
  }, []);

  // Render
  return (
    // Main
    <section className="form">
      {/* Main Heading */}
      <h2>Product</h2>

      {/* Form */}
      <form
        onSubmit={(e) =>
          handleSubmit(e, product, setProduct, setLoading, setProgress)
        }
      >
        {/* Form heading */}
        <h3>
          Add New Product{' '}
          <a
            href="https://api.spmweb.com.pk/uploads/template/products.xlsx"
            style={{
              textDecoration: 'none',
              color: 'inherit',
              margin: '0 10px 0 5px',
            }}
          >
            <i
              title="Download template for bulk upload"
              className="fas fa-arrow-circle-down"
            ></i>
          </a>
          <label htmlFor="excel_file_products" style={{ cursor: 'pointer' }}>
            <i
              title="Upload bulk products"
              className="fas fa-arrow-circle-up"
            ></i>

            <input
              type="file"
              id="excel_file_products"
              name="products"
              onChange={handleBulkUpload}
              hidden
            />
          </label>
          {/*&nbsp; &nbsp; (Seller ID is {user.id})*/}
        </h3>
        <div className="form-container two-form-col">
          {/* Col 1 */}
          <section>
            <input
              type="text"
              name="keyword"
              placeholder="Keyword"
              value={product.keyword}
              onChange={(e) => handleInput(e, setProduct)}
              required
            />
            <div className="form-input-container">
              <input
                type="text"
                name="brand_name"
                placeholder="Brand Name"
                value={product.brand_name}
                onChange={(e) => handleInput(e, setProduct)}
                required
              />
              <input
                type="text"
                name="amz_seller"
                placeholder="AMZ Seller"
                value={product.amz_seller}
                onChange={(e) => handleInput(e, setProduct)}
                required
              />
            </div>
            <div className="form-input-container">
              <MarketDropdown product={product} setProduct={setProduct} />
              <input
                type="text"
                name="chinese_seller"
                placeholder="Chinese Seller"
                value={product.chinese_seller}
                onChange={(e) => handleInput(e, setProduct)}
                required
              />
            </div>

            {/* Category Input */}
            <div className="form-options">
              <label htmlFor="category">Select Category: </label>
              <select
                required
                id="category"
                name="category"
                onChange={(e) => handleInput(e, setProduct)}
              >
                <option value="General" selected>
                  General
                </option>
                <option value="Kindle_&_Books">Kindle & Books</option>
                <option value="Electronics">Electronics</option>
                <option value="Health_&_Beauty">Health & Beauty</option>
                <option value="Baby_Products">Baby Products</option>
                <option value="Gaming_Devices">Gaming Devices</option>
                <option value="Fashion">Fashion (Clothes & shoes)</option>
                <option value="Electronic_Accessories">
                  Electronic Accessories
                </option>
                <option value="Automotive_Accessories">
                  Automotive Accessories
                </option>
                <option value="Expensive_Products">Expensive Products</option>
                <option value="Pet_Related">Pet Related</option>
                <option value="Home_&_Kitchen">Home & Kitchen</option>
              </select>
            </div>
            {/* End of category input */}

            <div className="form-input-container">
              <div className="input-file-container">
                <label htmlFor="amazonPic">Amazon Picture</label>
                <div>
                  <input
                    type="file"
                    id="amazonPic"
                    name="amazon_pic"
                    onChange={(e) => handleInput(e, setProduct, setProgress)}
                    required
                  />
                  <progress value={progress.amazon_pic} max="100" />
                </div>
              </div>
              <div className="input-file-container">
                <label htmlFor="productPicture">Picture</label>
                <div>
                  <input
                    type="file"
                    name="pic"
                    id="productPic"
                    onChange={(e) => handleInput(e, setProduct, setProgress)}
                    required
                  />
                  <progress value={progress.pic} max="100" />
                </div>
              </div>
            </div>
            <div className="form-input-container">
              <input
                type="text"
                name="commission"
                placeholder="Commission"
                value={product.commission}
                onChange={(e) => handleInput(e, setProduct)}
                required
              />
              <input
                type="text"
                name="portal_commission"
                placeholder="Portal Commission"
                value={product.portal_commission}
                onChange={(e) => handleInput(e, setProduct)}
                required
              />
            </div>
            <textarea
              name="instructions"
              id="instructions"
              cols="30"
              rows="4"
              placeholder="Instructions..."
              value={product.instructions}
              onChange={(e) => handleInput(e, setProduct)}
              required
            ></textarea>
          </section>

          {/* Col 2 */}
          <section>
            <textarea
              name="refund_conditions"
              cols="30"
              rows="5"
              placeholder="Refund Conditions"
              value={product.refund_conditions}
              onChange={(e) => handleInput(e, setProduct)}
              required
            ></textarea>
            <textarea
              name="commission_conditions"
              cols="30"
              rows="4"
              placeholder="Commission Conditions"
              value={product.commission_conditions}
              onChange={(e) => handleInput(e, setProduct)}
              required
            ></textarea>
            <div className="form-input-container">
              <input
                type="number"
                name="sale_limit"
                placeholder="Sale limit per day"
                value={product.sale_limit}
                onChange={(e) => handleInput(e, setProduct)}
                required
              />
              <input
                type="number"
                name="overall_sale_limit"
                placeholder="Overall sale limit"
                value={product.overall_sale_limit}
                onChange={(e) => handleInput(e, setProduct)}
                required
              />
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="form-submit-btn"
              disabled={loading}
            >
              {loading && <i className="fa fa-refresh fa-spin" />}
              &nbsp; Add Now
            </button>
          </section>
        </div>
      </form>
    </section>
  );
}

// Export
export default AddProduct;
