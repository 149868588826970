import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import MarketSearch from "../components/MarketSearch";

// utils
import { handleInput, handleSearch } from "../utils/search";

// Component
function Search({
	collection,
	field,
	category,
	status,
	setData,
	handleReload,
}) {
	// Initializing States
	let [input, setInput] = useState({
		field: field,
		query: "",
	});

	// Initializing History
	let history = useHistory();

	// Render
	return (
		<form
			className="search-container"
			onSubmit={(e) =>
				handleSearch(
					e,
					collection,
					status,
					input,
					setData,
					history,
					handleReload,
					category
				)
			}
		>
			{collection === "products" ? (
				<select name="field" onChange={(e) => handleInput(e, setInput)}>
					<option value="keyword">Keyword</option>
					<option value="seller_no">Seller ID</option>
					<option value="chinese_seller">Chinese Seller</option>
					<option value="brand_name">Brand</option>
					<option value="market">Market</option>
					<option value="product_no">Product ID</option>
				</select>
			) : collection === "orders" ? (
				<select name="field" onChange={(e) => handleInput(e, setInput)}>
					<option value="order_no">Order ID</option>
					<option value="order_number">Order Number</option>
					<option value="user_name">User Name</option>
					<option value="market">Market</option>
					<option value="order_number">AMZ ID</option>
					<option value="customer_email">Customer Email</option>
				</select>
			) : collection === "invoices" ? (
				<select name="field" onChange={(e) => handleInput(e, setInput)}>
					<option value="seller_name">Name</option>
					<option value="seller_email">Seller Email</option>
					<option value="invoice_no">Invoice No</option>
					<option value="account_platform">Platform</option>
					<option value="currency">Currency</option>
				</select>
			) : (
				<select name="field" onChange={(e) => handleInput(e, setInput)}>
					<option value="name">Name</option>
					<option value="email">Email</option>
					<option value="uid">User ID</option>
					<option value="gender">Gender</option>
					<option value="role">Role</option>
				</select>
			)}

			{input.field === "market" ? (
				<MarketSearch setInput={setInput} />
			) : (
				<input
					type="text"
					name="query"
					placeholder="Search"
					onChange={(e) => handleInput(e, setInput)}
				/>
			)}

			<button type="submit" className="search-btn">
				<i className="fas fa-search"></i>
			</button>
		</form>
	);
}

export default Search;
