// Init
import { analytics } from "../firebase";
import Compress from "browser-image-compression";
import axios from "axios";
import { toast } from "react-toastify";

// handle input
const handleInput = (e, setInvoice, invoice) => {
  // Checking if user uploaded an image
  if (e.target.name === "transaction_ss") {
    const file = e.target.files[0]; // Getting Image

    // Compression config
    const options = {
      maxSizeMB: 1, // (default: Number.POSITIVE_INFINITY)
      maxWidthOrHeight: 750, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
      useWebWorker: true, // optional, use multi-thread web worker, fallback to run in main-thread (default: true)
    };

    // Compressing
    Compress(file, options)
      .then((compressedBlob) => {
        // Adding metadeta
        compressedBlob.lastModifiedDate = new Date();

        // Convert the blob to file
        const image = new File([compressedBlob], file.name, {
          type: file.type,
          lastModified: Date.now(),
        });

        // Appending image to form data
        const formData = new FormData();
        formData.append("image", image);
        formData.append("invoice_id", invoice._id);

        // Upload Process
        axios
          .post("/images/invoices", formData)
          .then((res) => {
            toast.success("Image updated successfully.");
            // setting url to invoice
            setInvoice((prev) => {
              return {
                ...prev,
                transaction_ss: res.data.url,
              };
            });
          })
          .catch(() => {
            toast.error("Could not update image");
          });
      })
      .catch((e) => {
        // Show the user a toast message or notification that something went wrong while compressing file
      });
  } else {
    // Input
    setInvoice((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  }
};

// Handle Submit
const handleSubmit = (e, invoice, setInvoice, setEditing, setLoading) => {
  e.preventDefault();
  setLoading(true);

  // Update Request
  axios
    .put("/invoices", invoice)
    .then((res) => {
      // If success
      setEditing(false);
      setLoading(false);
      setInvoice(res.data);
      toast.success("Invoice updated successfully.");

      // Logging analytics
      analytics.logEvent("Invoices Updated");
    })
    .catch((err) => {
      // If session expired then redirect lo login page
      if (err.response.status === 401) {
        window.location = "/login";
        toast.error(err.response.data);
      } else if (err.response.status === 400) {
        toast.error(err.response.data);
        setLoading(false);
      } else {
        toast.error("Something went wrong.");
        setLoading(false);
      }
    });
};

// Export
export { handleInput, handleSubmit };
