// Init
import Compress from 'browser-image-compression';
import axios from 'axios';
import bcrypt from 'bcryptjs';
import { toast } from 'react-toastify';

// Handle Input
const handleInput = (e, setProfile) => {
  setProfile((prev) => {
    return {
      ...prev,
      [e.target.name]: e.target.value,
    };
  });
};

// Handle Profile Pic
const handleProfilePic = (e, user, handleReload) => {
  // Checking if user uploaded an image
  if (e.target.name === 'profile_pic' && e.target.files[0]) {
    const file = e.target.files[0]; // Getting Image

    // Compression config
    const options = {
      maxSizeMB: 1, // (default: Number.POSITIVE_INFINITY)
      maxWidthOrHeight: 300, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
      useWebWorker: true, // optional, use multi-thread web worker, fallback to run in main-thread (default: true)
    };

    // Compressing
    Compress(file, options)
      .then((compressedBlob) => {
        // Adding metadeta
        compressedBlob.lastModifiedDate = new Date();

        // Convert the blob to file
        const image = new File([compressedBlob], file.name, {
          type: file.type,
          lastModified: Date.now(),
        });

        // Adding file to form data
        const formData = new FormData();
        formData.append('profile_pic', image);
        formData.append('user_id', user._id);

        // Image Upload
        axios
          .post('/images/profile_pics', formData)
          .then(() => {
            toast.success('Image updated successfully.');
            window.location.reload();
          })
          .catch(() => {
            toast.error('Could not update the image.');
          });
      })
      .catch(console.log);
  }
};

// Handle Submit for profile update
const handleSubmit = (
  e,
  profile,
  setProfile,
  handleReload,
  setLoading,
  restricted,
) => {
  e.preventDefault();

  // Checking if user have permission to edit
  if (!restricted) {
    // If user is permitted to change
    setLoading(true);
    let password = profile.password;

    if (profile.newPassword) {
      password = bcrypt.hashSync(profile.newPassword, 10);
    }

    if (profile.newPassword && profile.newPassword.length < 8) {
      // if password weak
      toast.error('Password must be at least 8 characters!');
      setLoading(false);
    } else {
      // Updating user
      axios
        .put('/users', { ...profile, password: password })
        .then(() => {
          // if success
          toast.success('Profile updated successfully!');
          handleReload('all');
          setLoading(false);
          setProfile((prev) => ({ ...prev, newPassword: '' }));
        })
        .catch((err) => {
          // If session expired then redirect lo login page
          if (err.response.status === 401) {
            window.location = '/login';
            toast.error(err.response.data);
          } else if (err.response.status === 400) {
            toast.error(err.response.data);
            setLoading(false);
          } else if (err.response.status === 409) {
            toast.error('User already exist!');
            setLoading(false);
          } else {
            // if error
            toast.error('Something went wrong.');
            setLoading(false);
          }
        });
    }
  } else {
    // If user try to trick our system
    toast.error('Masti kar rya ain, reporting it to admin...');
  }
};

// Performance Calculator
const performanceCalc = (orders, setPerformance) => {
  // We will calculate the user performance by checking order status
  // Fomula: all order's length exept review deleted and cancelled / all order's length * 100
  const filtered = orders.filter(
    (item) => item.status !== 'review deleted' && item.status !== 'cancelled',
  );

  // Setting state with percentage
  setPerformance((filtered.length / orders.length) * 100);
};

// Export
export { handleInput, handleProfilePic, handleSubmit, performanceCalc };
