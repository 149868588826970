// Init
import { analytics } from "../firebase";
import Compress from "browser-image-compression";
import axios from "axios";
import { toast } from "react-toastify";

// handle input
const handleInput = (e, setOrder, order) => {
  // Checking if user uploaded an image
  if (
    e.target.name === "order_pic" ||
    e.target.name === "refund_pic" ||
    e.target.name === "review_pic"
  ) {
    const file = e.target.files[0]; // Getting Image

    // Compression config
    const options = {
      maxSizeMB: 1, // (default: Number.POSITIVE_INFINITY)
      maxWidthOrHeight: 750, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
      useWebWorker: true, // optional, use multi-thread web worker, fallback to run in main-thread (default: true)
    };

    // Compressing
    Compress(file, options)
      .then(async (compressedBlob) => {
        // Adding metadeta
        compressedBlob.lastModifiedDate = new Date();

        // Convert the blob to file
        const image = new File([compressedBlob], file.name, {
          type: file.type,
          lastModified: Date.now(),
        });

        // Appending image to form data
        const formData = new FormData();
        formData.append("image", image);
        formData.append("order_id", order._id);
        formData.append("image_name", e.target.name);

        // Uploading Process
        axios
          .post(`/images/orders/${e.target.name}s`, formData)
          .then((res) => {
            toast.success("Image updated successfully.");
            // setting url to product
            setOrder((prev) => {
              return {
                ...prev,
                [e.target.name]: res.data.url,
              };
            });
          })
          .catch(() => {
            toast.error("Could not update the image.");
          });
      })
      .catch((e) => {
        // Show the user a toast message or notification that something went wrong while compressing file
      });
  } else {
    // Input
    setOrder((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  }
};

// Handle submit for order update
const handleSubmit = (e, order, role, setLoading, setEditing, handleReload) => {
  e.preventDefault();
  setLoading(true);

  // Logging analytics
  analytics.logEvent("Orders Updated");

  if (order.order_number && order.customer_email) {
    // Checking if user have access
    // if user
    if (role === "user") {
      // Checking if user performing allowed action
      if (
        order.status === "ordered" ||
        order.status === "reviewed" ||
        order.status === "cancelled"
      ) {
        // checking order status to set data
        if (order.status === "reviewed") {
          axios
            .put("/orders", {
              ...order,
              reviewed_date: new Date().toLocaleString(),
            })
            .then(() => {
              handleReload("orders");
              setLoading(false);
              setEditing(false);
              toast.success("Order updated successfully.");
            })
            .catch((err) => {
              // If session expired then redirect lo login page
              if (err.response.status === 401) {
                window.location = "/login";
                toast.error(err.response.data);
              } else {
                setLoading(false);
                toast.error("Something went wrong.");
              }
            });
        } else {
          axios
            .put("/orders", {
              ...order,
            })
            .then(() => {
              handleReload("orders");
              setLoading(false);
              setEditing(false);
              toast.success("Order updated successfully.");
            })
            .catch((err) => {
              // If session expired then redirect lo login page
              if (err.response.status === 401) {
                window.location = "/login";
                toast.error(err.response.data);
              } else {
                toast.error("Something went wrong.");
                setLoading(false);
              }
            });
        }
      } else {
        // if user try to tick our system
        toast.error("Masti kar rya ain, reporting it to admin...");
        handleReload("orders");
        setLoading(false);
      }

      // if Manager
    } else if (role === "manager") {
      // Checking if user performing allowed action
      if (order.status !== "commissioned" || order.status !== "completed") {
        // checking order status to set data
        if (order.status === "refunded" || order.status === "reviewed") {
          axios
            .put("/orders", {
              ...order,
              [`${order.status}_date`]: new Date().toLocaleString(),
            })
            .then(() => {
              handleReload("orders");
              setLoading(false);
              setEditing(false);
              toast.success("Order updated successfully.");
            })
            .catch((err) => {
              // If session expired then redirect lo login page
              if (err.response.status === 401) {
                window.location = "/login";
                toast.error(err.response.data);
              } else {
                toast.error("Something went wrong.");
                setLoading(false);
              }
            });
        } else {
          axios
            .put("/orders", {
              ...order,
            })
            .then(() => {
              handleReload("orders");
              setLoading(false);
              setEditing(false);
              toast.success("Order updated successfully.");
            })
            .catch((err) => {
              // If session expired then redirect lo login page
              if (err.response.status === 401) {
                window.location = "/login";
                toast.error(err.response.data);
              } else {
                toast.error("Something went wrong.");
                setLoading(false);
              }
            });
        }
      } else {
        // if user try to tick our system
        toast.error("Masti kar rya ain, reporting it to admin...");
        handleReload("orders");
        setLoading(false);
      }

      // If Admin
    } else if (role === "admin") {
      // checking order status to set data
      if (
        order.status === "refunded" ||
        order.status === "reviewed" ||
        order.status === "completed"
      ) {
        axios
          .put("/orders", {
            ...order,
            [`${order.status}_date`]:
              order.status === "completed"
                ? new Date()
                : new Date().toLocaleString(),
          })
          .then(() => {
            toast.success("Order updated successfully.");
            handleReload("orders");
            setLoading(false);
            setEditing(false);
          })
          .catch((err) => {
            // If session expired then redirect lo login page
            if (err.response.status === 401) {
              window.location = "/login";
              toast.error(err.response.data);
            } else {
              toast.error("Something went wrong.");
              setLoading(false);
            }
          });
      } else {
        axios
          .put("/orders", {
            ...order,
          })
          .then(() => {
            handleReload("orders");
            setLoading(false);
            setEditing(false);
            toast.success("Order updated successfully.");
          })
          .catch((err) => {
            // If session expired then redirect lo login page
            if (err.response.status === 401) {
              window.location = "/login";
              toast.error(err.response.data);
            } else {
              toast.error("Something went wrong.");
              setLoading(false);
            }
          });
      }
    }
  } else {
    setLoading(false);
    toast.error("Please fill all the required fields...");
  }
};

// Export
export { handleInput, handleSubmit };
