// Init
import firebase from 'firebase/app';
import 'firebase/analytics';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDGtlFvyhmPeyfSBjDMKeEZm8exnP0soFM',
  authDomain: 'spmweb-pk.firebaseapp.com',
  projectId: 'spmweb-pk',
  storageBucket: 'spmweb-pk.appspot.com',
  messagingSenderId: '550519623236',
  appId: '1:550519623236:web:6e1f0abbbf5962de597611',
  measurementId: 'G-BQXPQL052Q',
};

// Initializing App
firebase.initializeApp(firebaseConfig);

// Initializing Features
const analytics = firebase.analytics();

// Export
export { analytics };
