// init
import axios from 'axios';
import { analytics } from '../../firebase';
import { toast } from 'react-toastify';

// Logging out
const logout = () => {
  axios
    .get('/auth/logout')
    .then(() => {
      window.location = '/login';

      // Logging analytics
      analytics.logEvent('Logout');
    })
    .catch((error) => {
      toast.error('Something went wrong.');
      console.log(error);
    });
};

// Export
export default logout;
