// Init
import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';

// Component
export const ExportUsers = ({ input, users }) => {
  // File Type
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  // Extension
  const fileExtension = '.xlsx';

  // Exporting
  const exportToCSV = (csvData, fileName) => {
    // Sorting
    const myHeader = [
      'uid',
      'name',
      'email',
      'cnic',
      'number',
      'role',
      'bankName',
      'bankAccount',
    ];

    const ws = XLSX.utils.json_to_sheet(csvData, { header: myHeader });

    const wb = { Sheets: { users: ws }, SheetNames: ['users'] };

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  };

  // Filtering users by input
  const handleSubmit = (input, users) => {
    if (input) {
      // Filtering users by role
      const filter = users.filter((item) => {
        delete item._id;
        delete item.profile_pic;
        delete item.gender;
        delete item.address;
        delete item.city;
        delete item.country;
        delete item.dateCreated;
        delete item.password;
        delete item.__v;
        return item.role === input;
      });

      // Calling Export function
      filter.length === 0
        ? toast.error('There are no users of this role')
        : exportToCSV(filter, input + 's - SPMWeb');
    } else {
      toast.error('Please select before generate');
    }
  };

  // Render
  return (
    <button
      className="form-submit-btn"
      style={{ margin: '10px 0' }}
      onClick={() => handleSubmit(input, users)}
    >
      Generate
    </button>
  );
};
