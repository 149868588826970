// Init
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logout from "../api/auth/logout";

// Image
import male from "../assets/male.png";

// Component
function Header({ user, messages, setToggle }) {
	// Intializing States
	let [clicked, setClicked] = useState(false);
	let [badge, setBadge] = useState(false);

	// Checking if there is unread messages
	useEffect(() => {
		if (
			messages.length !== 0 &&
			messages.filter((ary) => ary.sender !== user.uid).length !== 0
		) {
			setBadge(messages.filter((ary) => ary.sender !== user.uid).length);
		} else {
			setBadge(false);
		}
	}, [messages]);

	// Handle Header Click
	const handleHeaderClick = () => {
		if (clicked) {
			setClicked(false);
		}
	};

	// Handle Dropdown Click
	const handleDropdown = (e) => {
		e.stopPropagation();
		setClicked(!clicked);
	};

	// Render
	return (
		// Main
		<header onClick={handleHeaderClick}>
			{/* Support Button */}
			<div className="header-support">
				<Link to="/support">
					<i className="fas fa-envelope"></i>
					{badge && (
						<span className="badge">
							{badge > 9 ? "9+" : badge}
						</span>
					)}
				</Link>
			</div>

			{/* User */}
			<div className="header-user" onClick={handleDropdown}>
				<img src={user.profile_pic ? user.profile_pic : male} alt="" />
				<div className="header-dropdown-container">
					<span className="header-name">{user.name}</span>
					<i className="fas fa-sort-down"></i>

					{/* Header Dropdown */}
					<div
						className="header-dropdown"
						style={
							clicked ? { display: "block" } : { display: "none" }
						}
					>
						<Link to="/profile">
							<div>
								<i className="fas fa-user"></i>
								<span>Profile</span>
							</div>
						</Link>
						<div onClick={logout}>
							<i className="fas fa-sign-out-alt"></i>
							<span>Logout</span>
						</div>
					</div>
				</div>
			</div>

			<i
				className="fas fa-bars toggle-sidebar"
				onClick={() => setToggle(true)}
			></i>
		</header>
	);
}

export default Header;
