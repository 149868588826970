// Init
import { analytics } from "../firebase";
import Compress from "browser-image-compression";
import axios from "axios";
import { toast } from "react-toastify";

// handle create order input
const handleInput = (e, setOrder) => {
  // Checking if user uploaded an image
  if (e.target.name === "order_pic") {
    const file = e.target.files[0]; // Getting Image

    // Compression config
    const options = {
      maxSizeMB: 1, // (default: Number.POSITIVE_INFINITY)
      maxWidthOrHeight: 750, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
      useWebWorker: true, // optional, use multi-thread web worker, fallback to run in main-thread (default: true)
    };

    // Compressing
    Compress(file, options)
      .then((compressedBlob) => {
        // Adding metadeta
        compressedBlob.lastModifiedDate = new Date();

        // Convert the blob to file
        const image = new File([compressedBlob], file.name, {
          type: file.type,
          lastModified: Date.now(),
        });

        // if user is uploading image
        setOrder((prev) => {
          return {
            ...prev,
            order_pic: image,
          };
        });
      })
      .catch((e) => {
        console.log(e);
      });
  } else {
    // Input
    setOrder((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  }
};

// Handle release
const handleRelease = (id, handleReload, index, setLoading) => {
  setLoading(index);

  // Deleting the reservation
  axios
    .delete("/reservations", { data: { reservation_id: id } })
    .then(() => {
      // If all success
      toast.success("Reservation released successfully.");
      handleReload("reservations");
      setLoading();
    })
    .catch((err) => {
      // If session expired then redirect lo login page
      if (err.response.status === 401) {
        window.location = "/login";
        toast.error(err.response.data);
      } else {
        toast.error("Something went wrong.");
        handleReload("reservations");
      }
      // If any error accured
      console.log(err);
      setLoading();
    });
};

// Handle Submit for create order
const handleSubmit = (
  e,
  order,
  reservation,
  setOrdering,
  handleReload,
  setLoading
) => {
  e.preventDefault();

  // Validating if order has required fields
  setLoading(true);

  // Making form data
  const formData = new FormData();
  for (const property in order) {
    formData.append(property, order[property]);
  }
  formData.append("reservation_id", reservation._id);

  // Calling our respected API to create order
  axios
    .post("/orders", formData)
    .then(() => {
      // Success
      toast.success("Order created successfully.");
      setOrdering();
      setLoading(false);
      analytics.logEvent("Orders Created");
      handleReload("reservations");
    })
    .catch((err) => {
      setLoading(false);
      // If session expired then redirect lo login page
      if (err.response.status === 401) {
        window.location = "/login";
        toast.error(err.response.data);
      } else if (err.response.status === 400) {
        toast.error(err.response.data);
        setLoading(false);
      } else if (err.response.status === 404) {
        toast.error("Reservation is expired, Please reserve it again");
        setLoading(false);
      } else {
        toast.error("Something went wrong.");
        setLoading(false);
        console.log(err);
      }
    });
};

// Export
export { handleInput, handleSubmit, handleRelease };
