// Init
import React from 'react';

// Footer Component
function Footer() {
  return (
    <footer>
      <p>
        Developed By&nbsp;
        <a
          className="link"
          href="https://yousufkalim.com/contact"
          target="_blank"
          rel="noreferrer"
        >
          Yousuf Kalim
        </a>
      </p>
    </footer>
  );
}

// Export
export default Footer;
