// Init
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Pic Model
import PicModel from '../components/PicModel.jsx';

// utils
import { handleInput, handleSubmit } from '../utils/order';
import { copyOrder } from '../utils/copyClipboard';

// View Component
function ViewOrder({ order }) {
  return (
    // Details Section
    <ul>
      <li>
        <div>
          <h5>Order Number : </h5>
          <span>{order.order_number}</span>
        </div>
        <div>
          <h5>Customer Email : </h5>
          <span>{order.customer_email}</span>
        </div>
      </li>
      <li>
        <div>
          <h5>Product ID : </h5>
          <span>{order.product_no}</span>
        </div>
        <div>
          <h5>AMZ Review Link : </h5>
          <span>{order.customer_url}</span>
        </div>
      </li>
      <li>
        <div>
          <h5>Market : </h5>
          <span>{order.market}</span>
        </div>
        <div>
          <h5>Order Status : </h5>
          <span>{order.status}</span>
        </div>
      </li>
      <li>
        <div>
          <h5>Chinese Seller : </h5>
          <span>{order.chinese_seller}</span>
        </div>
        <div>
          <h5>Commission : </h5>
          <span>{order.commission}</span>
        </div>
      </li>
      <li>
        <div>
          <h5>Seller ID : </h5>
          <span>{order.seller_no}</span>
        </div>
        <div>
          <h5>PM ID : </h5>
          <span>{order.user_no}</span>
        </div>
      </li>
      <li>
        <div>
          <h5>Order Date : </h5>
          <span>{order.dateCreated}</span>
        </div>
        <div>
          <h5>Review Date : </h5>
          <span>{order.reviewed_date}</span>
        </div>
      </li>
      <li>
        <div>
          <h5>Refund Date : </h5>
          <span>{order.refunded_date}</span>
        </div>
        <div>
          <h5>Last Update Date : </h5>
          <span>{order.dateUpdated}</span>
        </div>
      </li>
      <li>
        <div>
          <h5>Remarks : </h5>
          <span>{order.remarks}</span>
        </div>
      </li>
    </ul>
  );
}

// Edit Component
function EditOrder({ order, user, setOrder, role, setEditing, handleReload }) {
  let [loading, setLoading] = useState(false);

  // Render
  return (
    // Form
    <form
      onSubmit={(e) =>
        handleSubmit(e, order, role, setLoading, setEditing, handleReload)
      }
    >
      <ul className="view-update-form">
        <h3>Update Order</h3>
        <li className="form-input-container">
          <input
            type="text"
            name="order_number"
            placeholder="Order Number"
            value={order.order_number}
            onChange={(e) => handleInput(e, setOrder)}
            required
          />
          <input
            type="text"
            name="customer_email"
            placeholder="Customer Email"
            value={order.customer_email}
            onChange={(e) => handleInput(e, setOrder)}
            required
          />
        </li>
        <input
          type="text"
          name="market"
          placeholder="Market"
          value={order.market}
          onChange={(e) => handleInput(e, setOrder)}
          required
        />
        <li className="form-input-container">
          <input
            type="text"
            name="customer_url"
            placeholder="AMZ Review Link"
            value={order.customer_url}
            onChange={(e) => handleInput(e, setOrder)}
          />

          {/* Order status options started */}
          {role === 'user' ? (
            <select
              name="status"
              id="order_status"
              onChange={(e) => handleInput(e, setOrder)}
              required
            >
              <option value="ordered" selected={order.status === 'ordered'}>
                Ordered
              </option>
              <option value="reviewed" selected={order.status === 'reviewed'}>
                Reviewed
              </option>
              <option value="cancelled" selected={order.status === 'cancelled'}>
                Cancelled
              </option>
            </select>
          ) : (
            <select
              name="status"
              id="order_status"
              onChange={(e) => handleInput(e, setOrder)}
              required
            >
              <option value="ordered" selected={order.status === 'ordered'}>
                Ordered
              </option>
              <option value="reviewed" selected={order.status === 'reviewed'}>
                Reviewed
              </option>
              <option value="delivered" selected={order.status === 'delivered'}>
                Pending Refund
              </option>
              <option value="refunded" selected={order.status === 'refunded'}>
                Refunded
              </option>
              <option
                value="review deleted"
                selected={order.status === 'review deleted'}
              >
                Review Deleted
              </option>
              <option value="on hold" selected={order.status === 'on hold'}>
                On Hold
              </option>
              <option value="pending" selected={order.status === 'pending'}>
                Pending
              </option>
              <option value="cancelled" selected={order.status === 'cancelled'}>
                Cancelled
              </option>
              {role === 'admin' ? (
                <>
                  <option
                    value="commissioned"
                    selected={order.status === 'commissioned'}
                  >
                    Commissioned
                  </option>
                  <option
                    value="completed"
                    selected={order.status === 'completed'}
                  >
                    Completed
                  </option>
                </>
              ) : null}
            </select>
          )}
          {/* Order status options ended */}
        </li>

        <textarea
          name="remarks"
          cols="30"
          rows="4"
          placeholder="Remarks"
          value={order.remarks}
          onChange={(e) => handleInput(e, setOrder)}
        ></textarea>

        <button type="submit" className="form-submit-btn" disabled={loading}>
          {loading && <i className="fa fa-refresh fa-spin" />}
          &nbsp; Update Now
        </button>
      </ul>
    </form>
  );
}

// Main Component
function Order({ role, user, orders, handleReload }) {
  // Initializing States
  let [order, setOrder] = useState({});
  let [editing, setEditing] = useState(false);
  let [editAccess, setEditAccess] = useState(false);
  let { orderNo } = useParams();
  let [picModel, setPicModel] = useState({ display: false, src: '' });

  // Filtering order and changing page title
  useEffect(() => {
    // Changing the title of page
    document.title = 'Order Detail - SPMWeb';

    // Filtering Order
    setOrder({
      ...orders.filter((ary) => ary.order_no == orderNo)[0],
    });
  }, [orders]);

  // Checking edit access
  useEffect(() => {
    if (role === 'user') {
      if (
        order.status === 'ordered' ||
        order.status === 'reviewed' ||
        order.status === 'cancelled'
      ) {
        setEditAccess(true);
      }
    } else if (role === 'manager') {
      if (order.status !== 'commissioned' || order.status !== 'completed') {
        setEditAccess(true);
      }
    } else if (role === 'admin') {
      setEditAccess(true);
    }
  }, [order]);

  // Render
  return (
    <section className="view">
      {/* Image Model */}
      {picModel.display && <PicModel model={picModel} setModel={setPicModel} />}

      {/* Head */}
      <div className="view-heading">
        <span>OVERVIEW</span>
        <h3>Order Details</h3>
      </div>

      {/* Body */}
      <div className="view-body">
        {/* Picture Section */}
        <div className="view-pics">
          {/* Order Pic */}
          <div className="view-pic">
            <h3>Order Picture</h3>
            {order.order_pic && (
              <img
                src={order.order_pic}
                alt="Order Pic"
                onClick={() =>
                  setPicModel({
                    display: true,
                    src: order.order_pic,
                  })
                }
              />
            )}

            {/* File Input */}
            <label htmlFor="order-pic" className="file-upload-btn">
              <i className="fas fa-camera"></i>
              &nbsp; Change Pic
              <input
                type="file"
                id="order-pic"
                name="order_pic"
                onChange={(e) => handleInput(e, setOrder, order)}
                hidden
              />
            </label>
          </div>

          {/* Refund Pic */}
          <div className="view-amz-pic">
            <h3>Refund Picture</h3>
            {order.refund_pic && (
              <img
                src={order.refund_pic}
                alt="Refund Pic"
                onClick={() =>
                  setPicModel({
                    display: true,
                    src: order.refund_pic,
                  })
                }
              />
            )}

            {/* File input */}
            {role === 'user' ? null : (
              <label htmlFor="refund-pic" className="file-upload-btn">
                <i className="fas fa-camera"></i>
                &nbsp; Change Pic
                <input
                  type="file"
                  id="refund-pic"
                  name="refund_pic"
                  onChange={(e) => handleInput(e, setOrder, order)}
                  hidden
                />
              </label>
            )}
          </div>

          {/* Refund Pic */}
          <div className="view-amz-pic">
            <h3>Review Picture</h3>
            {order.review_pic && (
              <img
                src={order.review_pic}
                alt="Review Pic"
                onClick={() =>
                  setPicModel({
                    display: true,
                    src: order.review_pic,
                  })
                }
              />
            )}

            {/* File input */}
            {role === 'user' ? (
              <label htmlFor="review-pic" className="file-upload-btn">
                <i className="fas fa-camera"></i>
                &nbsp; Change Pic
                <input
                  type="file"
                  id="review-pic"
                  name="review_pic"
                  onChange={(e) => handleInput(e, setOrder, order)}
                  hidden
                />
              </label>
            ) : null}
          </div>
        </div>

        {/* View Details Section */}
        <div className="view-details">
          {/* Head */}
          <div className="view-details-head">
            <h4>Details</h4>
            {editAccess && (
              <button
                className="green-btn"
                onClick={() => setEditing(!editing)}
              >
                <i className="fas fa-pen"></i>&nbsp;{' '}
                {editing ? 'Cancel' : 'Edit'}
              </button>
            )}
            <i
              className="far fa-copy"
              id="copy_icon"
              onClick={() => copyOrder(order)}
            ></i>
          </div>

          {/* Body */}
          {editing ? (
            <EditOrder
              role={role}
              user={user}
              order={order}
              setOrder={setOrder}
              setEditing={setEditing}
              handleReload={handleReload}
            />
          ) : (
            <ViewOrder order={order} />
          )}
        </div>
      </div>
    </section>
  );
}

// Export
export default Order;
