// Init
import { analytics } from '../firebase';
import Compress from 'browser-image-compression';
import axios from 'axios';
import { toast } from 'react-toastify';

// handle invoice input
const handleInput = (e, setInvoice) => {
  // Checking if user uploaded an image
  if (e.target.name === 'transaction_ss') {
    const file = e.target.files[0]; // Getting Image

    // Compression config
    const options = {
      maxSizeMB: 1, // (default: Number.POSITIVE_INFINITY)
      maxWidthOrHeight: 750, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
      useWebWorker: true, // optional, use multi-thread web worker, fallback to run in main-thread (default: true)
    };

    // Compressing
    Compress(file, options)
      .then((compressedBlob) => {
        // Adding metadeta
        compressedBlob.lastModifiedDate = new Date();

        // Convert the blob to file
        const image = new File([compressedBlob], file.name, {
          type: file.type,
          lastModified: Date.now(),
        });

        // if user is uploading image
        setInvoice((prev) => {
          return {
            ...prev,
            transaction_ss: image,
          };
        });
      })
      .catch((e) => {
        console.log(e);
      });
  } else {
    // Input
    setInvoice((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  }
};

// Handle Submit for Create Invoice
const handleSubmit = (e, invoice, setInvoice, setLoading) => {
  e.preventDefault();
  setLoading(true);

  // Making form data
  const formData = new FormData();
  for (const property in invoice) {
    formData.append(property, invoice[property]);
  }

  // Calling our respected API to create invoice
  axios
    .post('/invoices', formData)
    .then(() => {
      // Success
      setLoading(false);
      toast.success('Invoice created successfully!');
      analytics.logEvent('Invoice Created');

      setInvoice((prev) => {
        return {
          ...prev,
          currency: '',
          transaction_ss: '',
          amount: '',
        };
      });

      // Resetting File input value
      document.getElementById('transaction_ss').value = '';
    })
    .catch((err) => {
      setLoading(false);
      // If session expired then redirect lo login page
      if (err.response.status === 401) {
        window.location = '/login';
        toast.error(err.response.data);
      } else if (err.response.status === 400) {
        toast.error(err.response.data);
      } else {
        toast.error('Something went wrong.');
        setLoading(false);
        console.log({ ...err });
      }
    });
};

// Handle Status
const handleStatus = (id, setData, index, setLoading) => {
  setLoading(index);

  axios
    .patch('/invoices', { id })
    .then(() => {
      setLoading(null);
      analytics.logEvent('Invoices Paid');
      toast.success('Invoice paid successfully');

      // Deleting invoice from store
      setData((prev) => {
        return prev.filter((invoice) => invoice._id !== id);
      });
    })
    .catch((err) => {
      setLoading(null);
      // If session expired then redirect lo login page
      if (err.response.status === 401) {
        window.location = '/login';
        toast.error(err.response.data);
      } else {
        toast.error(err.response?.data || err.message);
        console.log({ ...err });
      }
    });
};

// Handle Delete
const handleDelete = (id, seller_no, setData) => {
  let sure = window.confirm('Are you sure you want to delete?');
  if (sure) {
    axios
      .delete('/invoices', {
        data: { invoice_id: id, seller_no: seller_no },
      })
      .then(() => {
        toast.success('Invoice deleted successfully');
        // Deleting invoice from local store
        setData((prev) => {
          return prev.filter((invoice) => invoice._id !== id);
        });
      })
      .catch((err) => {
        // If session expired then redirect lo login page
        if (err.response.status === 401) {
          toast.error(err.response.data);
          window.location = '/login';
        } else {
          toast.error('Something went wrong.');
          console.log({ ...err });
        }
      });
  }
};

// Export
export { handleInput, handleSubmit, handleStatus, handleDelete };
