import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// utils
import { handleDelete, handleStatus } from '../utils/invoices';
import Search from '../components/Search';
import { invoiceActionInput, invoiceActionSubmit } from '../utils/bulkActions';

// Pagination
import Pagination from '../components/Pagination.jsx';

function Invoices({ invoices, active, role, setInvoices, handleReload }) {
  // Initializing States
  let [data, setData] = useState([]);
  let [loading, setLoading] = useState(null);

  // Quick Action States
  let [takingAction, setTakingAction] = useState(false);
  let [actions, setActions] = useState({ from: '', to: '' });

  // Reloading component when it loads
  useEffect(() => {
    handleReload('invoices');
    // Changing the title of page
    document.title = 'Invoices - SPMWeb';
  }, []);

  return (
    // Main
    <section className="main">
      {/* Main Heading */}
      <h2>Invoices</h2>

      {/* Main Contianer */}
      <div className="main-container">
        {/* Head */}
        <div className="main-container-head">
          <div>
            <h3 style={{ display: 'inline' }}>
              {active ? 'Pending' : 'Paid'} Invoices
            </h3>
            &nbsp;-&nbsp;
            <span>(Total {invoices.length})</span>
            {/* Quick Action Button */}
            {active && role === 'admin' ? (
              <button
                className="green-btn"
                style={{ marginLeft: '30px' }}
                onClick={() => setTakingAction(!takingAction)}
              >
                {takingAction ? 'Cancel' : 'Quick Action'}
              </button>
            ) : null}
          </div>

          {/* Search */}
          <Search
            collection="invoices"
            field="seller_name"
            status={active}
            handleReload={handleReload}
            setData={setInvoices}
          />
        </div>

        {/* Quick Action */}
        {takingAction && active && role === 'admin' ? (
          <form
            className="form-container bulk-action-container"
            style={{
              padding: '20px 10px',
              borderBottom: '1px solid #e1e5eb',
            }}
            onSubmit={(e) =>
              invoiceActionSubmit(
                e,
                actions,
                setActions,
                setTakingAction,
                handleReload,
              )
            }
          >
            <section>
              <h3 className="action-heading">
                Select a date range to change status of invoices to paid
              </h3>
              <div>
                <label htmlFor="from">From</label>
                <DatePicker
                  required
                  selected={actions.from}
                  placeholderText="MM/DD/YYYY"
                  id="from"
                  onChange={(e) => invoiceActionInput(e, 'from', setActions)}
                />
              </div>
              <div>
                <label htmlFor="to">To</label>
                <DatePicker
                  required
                  selected={actions.to}
                  placeholderText="MM/DD/YYYY"
                  id="to"
                  onChange={(e) => invoiceActionInput(e, 'to', setActions)}
                />
              </div>
              <button type="submit" className="form-submit-btn">
                Paid
              </button>
            </section>
          </form>
        ) : null}

        {/* Body */}
        <div className="table-container">
          <table className="main-table">
            {/* Table Head */}
            <thead>
              <tr>
                <th>Name</th>
                <th>PMM Email</th>
                <th>Transaction Date</th>
                <th>Currency</th>
                <th>Amount</th>
                <th>Picture</th>
                <th>Platform</th>
                <th>Ac Title</th>
                <th>{active ? 'Creation' : 'Payment'} Date</th>
                {active && role === 'admin' ? <th></th> : null}
                {!active && role === 'manager' ? null : <th></th>}
              </tr>
            </thead>

            {/* Table Body */}
            <tbody>
              {data.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <Link to={`/profile/${item.seller_no}`} className="link">
                        {item.seller_name}
                      </Link>
                    </td>
                    <td>
                      <Link to={`/invoice/${item.invoice_no}`} className="link">
                        {item.seller_email}
                      </Link>
                    </td>
                    <td>{item.transaction_date}</td>
                    <td>{item.currency}</td>
                    <td>{item.amount}</td>
                    <td>
                      <img src={item.transaction_ss} alt="Screenshot" />
                    </td>
                    <td>{item.account_platform}</td>
                    <td>{item.account_title}</td>
                    <td>{active ? item.dateCreated : item.payment_date}</td>

                    {/* Button */}
                    {active && role === 'admin' ? (
                      <td>
                        <button
                          className="enabled-btn"
                          disabled={loading === index}
                          onClick={() =>
                            handleStatus(item._id, setData, index, setLoading)
                          }
                        >
                          {loading === index && (
                            <i className="fa fa-refresh fa-spin" />
                          )}
                          &nbsp; Paid
                        </button>
                      </td>
                    ) : null}
                    {role === 'manager' ? (
                      <th>
                        <Link
                          to={`/invoice/${item.invoice_no}`}
                          style={{
                            marginRight: '5px',
                          }}
                        >
                          <button className="green-btn">View</button>
                        </Link>
                      </th>
                    ) : (
                      <th>
                        <button
                          className="del-btn"
                          style={{
                            marginRight: '10px',
                          }}
                          onClick={() =>
                            handleDelete(item._id, item.seller_no, setData)
                          }
                        >
                          Delete
                        </button>
                      </th>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <Pagination data={invoices} setData={setData} />
      </div>
    </section>
  );
}

export default Invoices;
