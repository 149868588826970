// Init
import axios from "axios";
import { toast } from "react-toastify";

// Handle Input
function handleInput(e, setAccount) {
  setAccount((prev) => {
    return {
      ...prev,
      [e.target.name]: e.target.value,
    };
  });
}

// handle submit
const handleSubmit = (e, account, setAccount, setCreating, handleReload) => {
  e.preventDefault();

  // POST
  axios
    .post("/accounts", account)
    .then(() => {
      setAccount({
        account_no: "",
        title: "",
        platform: "",
        email: "",
      });
      setCreating(false);
      handleReload("accounts");
      toast.success("Account created successfully");
    })
    .catch((err) => {
      // If session expired then redirect lo login page
      if (err.response.status === 401) {
        window.location = "/login";
        toast.error(err.response?.data);
      } else if (err.response.status === 400) {
        toast.error(err.response?.data);
      } else {
        toast.error("Something went wrong.");
        console.log({ ...err });
      }
    });
};

// handle delete
const handleDelete = (id, handleReload) => {
  let sure = window.confirm("Are you sure you want to delete?");
  if (sure) {
    axios
      .delete("/accounts", { data: { account_id: id } })
      .then(() => {
        handleReload("accounts");
        toast.success("Account deleted successfully");
      })
      .catch((err) => {
        // If session expired then redirect lo login page
        if (err.response.status === 401) {
          window.location = "/login";
          toast.error(err.response.data);
        } else {
          toast.error("Something went wrong.");
          console.log({ ...err });
        }
      });
  }
};

// Export
export { handleInput, handleSubmit, handleDelete };
