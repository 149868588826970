// Init
import React, { useState, useEffect } from "react";

// Controller
import { handleInput, handleSubmit } from "../utils/invoices.js";

function AddInvoice({ accounts, handleReload }) {
  // Initializing States
  let [invoice, setInvoice] = useState({});
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    handleReload("accounts");
  }, []);

  useEffect(() => {
    if (accounts.length > 0) {
      setInvoice((prev) => ({ ...prev, account: accounts[0]._id }));
    }
  }, [accounts]);

  // Render
  return (
    // Main form container
    <section className="form">
      {/* Main Heading */}
      <h2>Invoices</h2>

      {/* Form */}
      <form onSubmit={(e) => handleSubmit(e, invoice, setInvoice, setLoading)}>
        {/* form heading */}
        <h3>Create New Invoice</h3>
        <div className="form-container two-form-col">
          {/* Col 1 */}
          <section>
            <div className="input-file-container">
              <label htmlFor="">Transaction Date *</label>
              <input
                type="date"
                name="transaction_date"
                value={invoice.trasaction_date}
                onChange={(e) => handleInput(e, setInvoice)}
                required
              />
            </div>
            <div className="form-options">
              <label htmlFor="account">Account: </label>
              <select
                required
                name="account"
                id="account"
                onChange={(e) => handleInput(e, setInvoice)}
              >
                {accounts.map((account) => {
                  return (
                    <option
                      key={account._id}
                      value={account._id}
                    >{`${account.platform} - ${account.title}`}</option>
                  );
                })}
              </select>
            </div>
            <input
              type="text"
              name="currency"
              placeholder="Currency (e.g., USD, RMB, EUR) *"
              maxLength="3"
              value={invoice.currency}
              onChange={(e) => handleInput(e, setInvoice)}
              required
            />
            <div className="input-file-container">
              <label htmlFor="transaction_ss">Transaction Screen Shot</label>
              <input
                type="file"
                name="transaction_ss"
                id="transaction_ss"
                onChange={(e) => handleInput(e, setInvoice)}
                required
              />
            </div>
            <input
              type="number"
              step="any"
              name="amount"
              placeholder="Amount *"
              value={invoice.amount}
              onChange={(e) => handleInput(e, setInvoice)}
              required
            />

            {/* Submit Button */}
            <div className="form-buttons-container">
              <button
                type="submit"
                className="form-submit-btn"
                disabled={loading}
              >
                {loading && <i className="fa fa-refresh fa-spin" />}
                &nbsp; Add Now
              </button>
            </div>
          </section>
        </div>
      </form>
    </section>
  );
}

export default AddInvoice;
