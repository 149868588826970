// Init
import React from "react";
import { orderDateRange } from "../utils/dateRange";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";

// Component
export const ExportCSV = ({ input, orders }) => {
  // File Type
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  // Extension
  const fileExtension = ".xlsx";

  // Exporting
  const exportToCSV = (csvData, fileName) => {
    // Sorting
    const myHeader = [
      "order_number",
      "customer_email",
      "market",
      "order_no",
      "status",
      "product_no",
      "seller_no",
      "user_no",
      "user_name",
      "commission",
      "portal_commission",
      "dateCreated",
      "dateUpdated",
      "reviewed_date",
      "refunded_date",
      "customer_url",
      "review_pic",
    ];

    const ws = XLSX.utils.json_to_sheet(csvData, { header: myHeader });

    const wb = { Sheets: { orders: ws }, SheetNames: ["orders"] };

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  };

  // Filtering orders by input
  const handleSubmit = (input, orders) => {
    if (input.from && input.to && input.excel_status) {
      // Filtering Orders
      const filter = orders.filter((item) => {
        // Deleting extra keys
        delete item.seller_id;
        delete item._id;
        delete item.pic;
        delete item.user_id;
        delete item.order_pic;
        delete item.product_id;
        delete item.refund_pic;
        delete item.expiryDate;
        delete item.__v;

        // Filtering order according to input
        if (input.user_no) {
          // If user want a specific user's orders
          return (
            orderDateRange(item.dateUpdated, input) &&
            item.status === input.excel_status &&
            (item.user_no === parseInt(input.user_no) ||
              item.seller_no === parseInt(input.user_no))
          );
        } else {
          // If user want all order between these dates
          return (
            orderDateRange(item.dateUpdated, input) &&
            item.status === input.excel_status
          );
        }
      });

      // Sorting by given method
      filter.sort((a, b) => a[input.sortMethod] - b[input.sortMethod]);

      // Calling Export function
      filter.length === 0
        ? toast.error("No records found!")
        : exportToCSV(filter, input.excel_status);
    } else {
      toast.error("Please select before generate!");
    }
  };

  // Render
  return (
    <button
      className="form-submit-btn"
      style={{ margin: "5px 0" }}
      onClick={() => handleSubmit(input, orders)}
    >
      Generate
    </button>
  );
};
