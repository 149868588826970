// Init
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Controller
import { handleInput, handleSubmit } from '../utils/signup';

function Signup() {
  // Initializing States
  let [user, setUser] = useState({
    name: '',
    cnic: '',
    email: '',
    password: '',
    confirmPassword: '',
    gender: 'male',
    role: 'user',
    address: '',
    city: '',
    country: '',
    number: '',
    bankName: '',
    bankAccount: '',
    referral_name: '',
    referral_number: '',
  });
  let [loading, setLoading] = useState(false);

  // Changing the title of page
  useEffect(() => {
    document.title = 'Add User - SPMWeb';
  }, []);

  // Render
  return (
    <section className="signup">
      {/* Main */}
      <section className="form signup-container">
        {/* Heading */}
        <h2>Register Yourself</h2>

        {/* Form */}
        <form onSubmit={(e) => handleSubmit(e, user, setUser, setLoading)}>
          {/* Input Container */}
          <div className="form-container">
            {/* Col 1 */}
            <section>
              <div className="form-input-container">
                <input
                  type="text"
                  name="name"
                  placeholder="Full Name *"
                  value={user.name}
                  onChange={(e) => handleInput(e, setUser)}
                  required
                />
                <input
                  type="number"
                  name="cnic"
                  placeholder="CNIC *"
                  value={user.cnic}
                  onChange={(e) => handleInput(e, setUser)}
                  required
                />
              </div>
              <input
                type="text"
                name="email"
                placeholder="Email *"
                value={user.email}
                onChange={(e) => handleInput(e, setUser)}
                required
              />
              <input
                type="password"
                name="password"
                placeholder="Password *"
                value={user.password}
                onChange={(e) => handleInput(e, setUser)}
                required
              />
              <input
                type="password"
                name="confirmPassword"
                placeholder="Confirm Password *"
                value={user.confirmPassword}
                onChange={(e) => handleInput(e, setUser)}
                required
              />
              <div className="form-options-container">
                <div className="form-radio">
                  <span>Gender: </span>
                  <label htmlFor="male">Male</label>
                  <input
                    type="radio"
                    id="male"
                    name="gender"
                    value="male"
                    checked={user.gender === 'male'}
                    onChange={(e) => handleInput(e, setUser)}
                  />
                  <label htmlFor="female">Female</label>
                  <input
                    type="radio"
                    id="female"
                    name="gender"
                    value="female"
                    checked={user.gender === 'female'}
                    onChange={(e) => handleInput(e, setUser)}
                  />
                </div>
                <div className="form-options">
                  <label htmlFor="role">Role: </label>
                  <select
                    required
                    name="role"
                    id="role"
                    onChange={(e) => handleInput(e, setUser)}
                  >
                    <option value="user">PM</option>
                    <option value="manager">PMM</option>
                  </select>
                </div>
              </div>
              <input
                type="text"
                name="address"
                placeholder="Address"
                value={user.address}
                onChange={(e) => handleInput(e, setUser)}
              />
              <div className="form-input-container">
                <input
                  type="text"
                  name="city"
                  placeholder="City"
                  value={user.city}
                  onChange={(e) => handleInput(e, setUser)}
                />
                <input
                  type="text"
                  name="country"
                  placeholder="Country"
                  value={user.country}
                  onChange={(e) => handleInput(e, setUser)}
                />
              </div>
              <input
                type="tel"
                name="number"
                placeholder="Phone Number *"
                value={user.number}
                onChange={(e) => handleInput(e, setUser)}
                required
              />
              <div className="form-input-container">
                <input
                  type="text"
                  name="bankName"
                  placeholder="Bank Name"
                  value={user.bankName}
                  onChange={(e) => handleInput(e, setUser)}
                />
                <input
                  type="text"
                  name="bankAccount"
                  placeholder="Bank Account"
                  value={user.bankAccount}
                  onChange={(e) => handleInput(e, setUser)}
                />
              </div>
              <div className="form-input-container">
                <input
                  type="text"
                  name="referral_name"
                  placeholder="Referral Name"
                  required={user.role === 'manager'}
                  value={user.referral_name}
                  onChange={(e) => handleInput(e, setUser)}
                />
                <input
                  type="text"
                  name="referral_number"
                  placeholder="Referral Number"
                  required={user.role === 'manager'}
                  value={user.referral_number}
                  onChange={(e) => handleInput(e, setUser)}
                />
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                className="form-submit-btn"
                disabled={loading}
              >
                {loading && <i className="fa fa-refresh fa-spin" />}
                &nbsp; Register Now
              </button>

              {/* Social Buttons Section */}
              {/*<div className="social_buttons">*/}
              {/*  <a*/}
              {/*    href={*/}
              {/*      user.role === "user"*/}
              {/*        ? "https://chat.whatsapp.com/Dxro5np4hLo7NPQyWlKUjj"*/}
              {/*        : "https://chat.whatsapp.com/JpU0uI29rDk7zCawJSh4KM"*/}
              {/*    }*/}
              {/*    target="_blank"*/}
              {/*    rel="external"*/}
              {/*  >*/}
              {/*    <i className="fab fa-whatsapp"></i>*/}
              {/*    &nbsp;Whatsapp*/}
              {/*  </a>*/}
              {/*  <a*/}
              {/*    href="https://www.facebook.com/groups/pmportal/?ref=share&exp=e8c2"*/}
              {/*    target="_blank"*/}
              {/*    rel="external"*/}
              {/*  >*/}
              {/*    <i className="fab fa-facebook"></i>*/}
              {/*    &nbsp;Facebook*/}
              {/*  </a>*/}
              {/*</div>*/}

              {/* Already Registered */}
              <p className="login-link-btn">
                Already registered?&nbsp;
                <Link className="link" to="/login">
                  Login here
                </Link>
              </p>
            </section>
          </div>
        </form>
      </section>
    </section>
  );
}

export default Signup;
