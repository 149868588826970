// Init
import { analytics } from "../firebase";
import Compress from "browser-image-compression";
import axios from "axios";
import { toast } from "react-toastify";

// handle input
const handleInput = (e, setProduct, product) => {
  // Checking if user uploaded an image
  if (e.target.name === "amazon_pic" || e.target.name === "pic") {
    const file = e.target.files[0]; // Getting Image

    // Compression config
    const options = {
      maxSizeMB: 1, // (default: Number.POSITIVE_INFINITY)
      maxWidthOrHeight: 750, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
      useWebWorker: true, // optional, use multi-thread web worker, fallback to run in main-thread (default: true)
    };

    // Compressing
    Compress(file, options)
      .then((compressedBlob) => {
        // Adding metadeta
        compressedBlob.lastModifiedDate = new Date();

        // Convert the blob to file
        const image = new File([compressedBlob], file.name, {
          type: file.type,
          lastModified: Date.now(),
        });

        // Adding image to form data
        const formData = new FormData();
        formData.append("image", image);
        formData.append("product_id", product._id);
        formData.append("image_name", e.target.name);

        // Upload Process
        axios
          .post(`/images/products/${e.target.name}s`, formData)
          .then((res) => {
            toast.success("Image updated successfully!");
            // setting url to product
            setProduct((prev) => {
              return {
                ...prev,
                [e.target.name]: res.data.url,
              };
            });
          })
          .catch(() => {
            toast.error("Could not update the image.");
          });
      })
      .catch((e) => {
        // Show the user a toast message or notification that something went wrong while compressing file
      });
  } else {
    // Input
    setProduct((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  }
};

// Handle Submit
const handleSubmit = (e, product, setEditing, setLoading) => {
  e.preventDefault();
  setLoading(true);

  // Checking if sale limit lesser than overall limit
  if (parseInt(product.sale_limit) > parseInt(product.overall_sale_limit)) {
    toast.error("Sale limit must be lesser than overall limit...");
    setLoading(false);
  } else {
    // getting the product to check latest updates
    axios
      .put("/products", product)
      .then(() => {
        // If success
        setEditing(false);
        setLoading(false);
        toast.success("Product updated successfully.");

        // Logging analytics
        analytics.logEvent("Products Updated");
      })
      .catch((err) => {
        // If session expired then redirect lo login page
        if (err.response.status === 401) {
          window.location = "/login";
          toast.error(err.response.data);
        } else if (err.response.status === 400 || err.response.status === 403) {
          toast.error(err.response.data);
          setLoading(false);
        } else if (err.response.status === 422) {
          toast.error(err.response.data);
          setLoading(false);
        } else {
          toast.error("Something went wrong.");
          setLoading(false);
        }
      });
  }
};

// Export
export { handleInput, handleSubmit };
