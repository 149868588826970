// Init
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Utils
import {
  handleInput,
  handleSubmit,
  handleRelease,
} from '../utils/reservations.js';

// Create Order Component
function CreateOrder({ setOrdering, reservation, handleReload }) {
  // Initializing States
  let [order, setOrder] = useState({});
  let [loading, setLoading] = useState(false);

  // Render
  return (
    // Main form container
    <section className="form">
      {/* Main Heading */}
      <h2>Orders</h2>

      {/* Form */}
      <form
        onSubmit={(e) =>
          handleSubmit(
            e,
            order,
            reservation,
            setOrdering,
            handleReload,
            setLoading,
          )
        }
      >
        {/* form heading */}
        <h3>Add New Order</h3>
        <div className="form-container two-form-col">
          {/* Col 1 */}
          <section>
            <input
              type="text"
              name="order_number"
              placeholder="Order Number*"
              value={order.order_number}
              onChange={(e) => handleInput(e, setOrder)}
              required
            />
            <input
              type="email"
              name="customer_email"
              placeholder="Customer Email Address*"
              value={order.customer_email}
              onChange={(e) => handleInput(e, setOrder)}
              required
            />
            <input
              type="text"
              name="market"
              placeholder="Market"
              value={order.market}
              onChange={(e) => handleInput(e, setOrder)}
            />
            <div className="input-file-container">
              <label htmlFor="order_pic">Order Pic</label>
              <input
                type="file"
                name="order_pic"
                id="order_pic"
                onChange={(e) => handleInput(e, setOrder)}
                required
              />
            </div>
            <input
              type="text"
              name="customer_url"
              placeholder="AMZ Review Link"
              value={order.customer_url}
              onChange={(e) => handleInput(e, setOrder)}
            />

            {/* Submit Button */}
            <div className="form-buttons-container">
              <button className="del-btn" onClick={() => setOrdering(false)}>
                Cancel
              </button>
              <button
                type="submit"
                className="form-submit-btn"
                disabled={loading}
              >
                {loading && <i className="fa fa-refresh fa-spin" />}
                &nbsp; Add Now
              </button>
            </div>
          </section>
        </div>
      </form>
    </section>
  );
}

// Table Component
function ViewReservations({
  role,
  reservations,
  setOrdering,
  handleReload,
  setReservation,
}) {
  let [loading, setLoading] = useState();
  // Render
  return (
    // Main
    <section className="main">
      {/* Main Heading */}
      <h2>Reservations</h2>

      {/* Main Contianer */}
      <div className="main-container">
        {/* Head */}
        <div className="main-container-head">
          <div>
            <h3>Active Reservations</h3>
            &nbsp;-&nbsp;
            <span>(Total {reservations.length})</span>
          </div>
        </div>

        {/* Body */}
        <div className="table-container">
          <table className="main-table">
            {/* Table Head */}
            <thead>
              <tr>
                <th>#</th>
                <th>User</th>
                <th>Product ID</th>
                <th>Creation Time</th>
                <th>Image</th>
                <th></th>
                <th></th>
              </tr>
            </thead>

            {/* Table Body */}
            <tbody>
              {reservations.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.reservation_no}</td>
                    <td>
                      <Link
                        className="link"
                        to={
                          role !== 'user'
                            ? `/profile/${item.user_no}`
                            : '/profile'
                        }
                      >
                        {item.user_name}
                      </Link>
                    </td>
                    <td>
                      <Link className="link" to={`/product/${item.product_no}`}>
                        {item.product_no}
                      </Link>
                    </td>
                    <td>{item.dateCreated}</td>
                    <td>
                      <img src={item.pic} alt="Product Pic" />
                    </td>

                    {/* Buttons */}
                    <td>
                      {role === 'user' ? (
                        <button
                          className="green-btn"
                          onClick={() => {
                            setOrdering(true);
                            setReservation({
                              ...item,
                            });
                          }}
                        >
                          Create Order
                        </button>
                      ) : null}
                    </td>
                    <td>
                      <button
                        className="del-btn"
                        disabled={loading === index}
                        onClick={() =>
                          handleRelease(
                            item._id,
                            handleReload,
                            index,
                            setLoading,
                          )
                        }
                      >
                        {loading === index && (
                          <i className="fa fa-refresh fa-spin" />
                        )}
                        &nbsp; Release
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}

// Main component
function Reservations({ role, reservations, handleReload }) {
  // Initializing States
  let [ordering, setOrdering] = useState(false);
  let [reservation, setReservation] = useState({});

  // Reloading component when it loads
  useEffect(() => {
    handleReload('reservations');
    // Changing the title of page
    document.title = 'Reservations - SPMWeb';
  }, []);

  // Render
  return ordering ? (
    <CreateOrder
      setOrdering={setOrdering}
      reservation={reservation}
      handleReload={handleReload}
    />
  ) : (
    <ViewReservations
      role={role}
      setReservation={setReservation}
      reservations={reservations}
      setOrdering={setOrdering}
      handleReload={handleReload}
    />
  );
}

// export
export default Reservations;
