// Init
import axios from "axios";
import { toast } from "react-toastify";

// Handle Input for invoice
const orderActionInput = (e, setActions, field) => {
  setActions((prev) => {
    if (field) {
      return {
        ...prev,
        [field]: e,
      };
    } else {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    }
  });
};

// handle Submit for bulk invoice submit
const orderActionSubmit = (
  e,
  actions,
  setActions,
  setTakingActions,
  handleReload
) => {
  e.preventDefault();

  // Making from date format to compare
  const from = [
    actions.from.getMonth() + 1,
    actions.from.getDate(),
    actions.from.getFullYear(),
  ];
  const fromYear = from[2];
  const fromMonth = from[0] < 10 ? 0 + "" + from[0] : from[0];
  const fromDay = from[1] < 10 ? 0 + "" + from[1] : from[1];
  const fromFullDate = [fromYear, fromMonth, fromDay].join("");

  // Making to date format to compare
  const to = [
    actions.to.getMonth() + 1,
    actions.to.getDate(),
    actions.to.getFullYear(),
  ];
  const toYear = to[2];
  const toMonth = to[0] < 10 ? 0 + "" + to[0] : to[0];
  const toDay = to[1] < 10 ? 0 + "" + to[1] : to[1];
  const toFullDate = [toYear, toMonth, toDay].join("");

  // API Call
  axios
    .patch("/orders", {
      ...actions,
      from: fromFullDate,
      to: toFullDate,
    })
    .then(() => {
      setActions((prev) => ({ ...prev, from: "", to: "", user_no: "" }));
      setTakingActions(false);
      handleReload("orders");
      toast.success("Bulk action completed.");
    })
    .catch((err) => {
      // If session expired then redirect lo login page
      if (err.response.status === 401) {
        window.location = "/login";
        toast.error(err.response.data);
      } else {
        toast.error("Something went wrong.");
      }
      console.log({ ...err });
    });
};

/*
=====================
Invoices
=====================
*/

// Handle Input for invoice
const invoiceActionInput = (e, field, setActions) => {
  setActions((prev) => {
    return {
      ...prev,
      [field]: e,
    };
  });
};

// handle Submit for bulk invoice submit
const invoiceActionSubmit = (
  e,
  actions,
  setActions,
  setTakingActions,
  handleReload
) => {
  e.preventDefault();

  // Making from date format to compare
  const from = [
    actions.from.getMonth() + 1,
    actions.from.getDate(),
    actions.from.getFullYear(),
  ];
  const fromYear = from[2];
  const fromMonth = from[0] < 10 ? 0 + "" + from[0] : from[0];
  const fromDay = from[1] < 10 ? 0 + "" + from[1] : from[1];
  const fromFullDate = [fromYear, fromMonth, fromDay].join("");

  // Making to date format to compare
  const to = [
    actions.to.getMonth() + 1,
    actions.to.getDate(),
    actions.to.getFullYear(),
  ];
  const toYear = to[2];
  const toMonth = to[0] < 10 ? 0 + "" + to[0] : to[0];
  const toDay = to[1] < 10 ? 0 + "" + to[1] : to[1];
  const toFullDate = [toYear, toMonth, toDay].join("");

  // API Call
  axios
    .patch("/invoices/actions", { from: fromFullDate, to: toFullDate })
    .then(() => {
      toast.success("Bulk action completed.");
      setActions({ from: "", to: "" });
      setTakingActions(false);
      handleReload("invoices");
    })
    .catch((err) => {
      // If session expired then redirect lo login page
      if (err.response.status === 401) {
        window.location = "/login";
        toast.error(err.response.data);
      } else {
        toast.error("Something went wrong.");
      }
      console.log({ ...err });
    });
};

// Export
export {
  orderActionInput,
  orderActionSubmit,
  invoiceActionInput,
  invoiceActionSubmit,
};
