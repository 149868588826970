// Init
import React, { useState, useEffect } from 'react';

// utils
import { handleInput, handleSubmit } from '../utils/addUser';

// Main Component
function AddUser() {
  // Initializing States
  let [user, setUser] = useState({
    name: '',
    cnic: '',
    email: '',
    password: '',
    confirmPassword: '',
    gender: 'male',
    role: 'user',
    address: '',
    city: '',
    country: '',
    number: '',
    bankName: '',
    bankAccount: '',
  });
  let [loading, setLoading] = useState(false);

  // Changing the title of page
  useEffect(() => {
    document.title = 'Add User - SPMWeb';
  }, []);

  // Render
  return (
    <React.Fragment>
      {/* Main */}
      <section className="form">
        {/* Heading */}
        <h2>User</h2>

        {/* Form */}
        <form onSubmit={(e) => handleSubmit(e, user, setUser, setLoading)}>
          {/* Form heading */}
          <h3>Add New User</h3>
          {/* Input Container */}
          <div className="form-container two-form-col">
            {/* Col 1 */}
            <section>
              <div className="form-input-container">
                <input
                  type="text"
                  name="name"
                  placeholder="Name *"
                  value={user.name}
                  onChange={(e) => handleInput(e, setUser)}
                  required
                />
                <input
                  type="number"
                  name="cnic"
                  placeholder="CNIC *"
                  value={user.cnic}
                  onChange={(e) => handleInput(e, setUser)}
                  required
                />
              </div>
              <input
                type="email"
                name="email"
                placeholder="Email *"
                value={user.email}
                onChange={(e) => handleInput(e, setUser)}
                required
              />
              <input
                type="password"
                name="password"
                placeholder="Password *"
                value={user.password}
                onChange={(e) => handleInput(e, setUser)}
                required
              />
              <input
                type="password"
                name="confirmPassword"
                placeholder="Confirm Password *"
                value={user.confirmPassword}
                onChange={(e) => handleInput(e, setUser)}
                required
              />
              <div className="form-options-container">
                <div className="form-radio">
                  <span>Gender: </span>
                  <label htmlFor="male">Male</label>
                  <input
                    type="radio"
                    id="male"
                    name="gender"
                    value="male"
                    checked={user.gender === 'male'}
                    onChange={(e) => handleInput(e, setUser)}
                  />
                  <label htmlFor="female">Female</label>
                  <input
                    type="radio"
                    id="female"
                    name="gender"
                    value="female"
                    checked={user.gender === 'female'}
                    onChange={(e) => handleInput(e, setUser)}
                  />
                </div>
                <div className="form-options">
                  <label htmlFor="role">Role: </label>
                  <select
                    required
                    name="role"
                    id="role"
                    onChange={(e) => handleInput(e, setUser)}
                  >
                    <option value="user">User</option>
                    <option value="manager">Manager</option>
                    <option value="admin">Admin</option>
                  </select>
                </div>
              </div>
            </section>

            {/* Col 2 */}
            <section>
              <input
                type="text"
                name="address"
                placeholder="Address"
                value={user.address}
                onChange={(e) => handleInput(e, setUser)}
              />
              <div className="form-input-container">
                <input
                  type="text"
                  name="city"
                  placeholder="City"
                  value={user.city}
                  onChange={(e) => handleInput(e, setUser)}
                />
                <input
                  type="text"
                  name="country"
                  placeholder="Country"
                  value={user.country}
                  onChange={(e) => handleInput(e, setUser)}
                />
              </div>
              <input
                type="tel"
                name="number"
                placeholder="Phone Number *"
                value={user.number}
                onChange={(e) => handleInput(e, setUser)}
                required
              />
              <div className="form-input-container">
                <input
                  type="text"
                  name="bankName"
                  placeholder="Bank Name"
                  value={user.bankName}
                  onChange={(e) => handleInput(e, setUser)}
                />
                <input
                  type="text"
                  name="bankAccount"
                  placeholder="Bank Account"
                  value={user.bankAccount}
                  onChange={(e) => handleInput(e, setUser)}
                />
              </div>
              <div className="form-input-container">
                <input
                  type="text"
                  name="referral_name"
                  placeholder="Referral Name"
                  value={user.referral_name}
                  onChange={(e) => handleInput(e, setUser)}
                />
                <input
                  type="text"
                  name="referral_number"
                  placeholder="Referral Number"
                  value={user.referral_number}
                  onChange={(e) => handleInput(e, setUser)}
                />
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                className="form-submit-btn"
                disabled={loading}
              >
                {loading && <i className="fa fa-refresh fa-spin" />}
                &nbsp; Add Now
              </button>
            </section>
          </div>
        </form>
      </section>
    </React.Fragment>
  );
}

// Export
export default AddUser;
