// Init
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// utils
import Search from '../components/Search';
import { orderActionInput, orderActionSubmit } from '../utils/bulkActions';

// Pagination
import Pagination from '../components/Pagination.jsx';

// Component
function Orders({ status, orders, setOrders, handleReload, role }) {
  // Initializing States
  let [data, setData] = useState([]);

  // Quick Action States
  let [takingAction, setTakingAction] = useState(false);
  let [actions, setActions] = useState({
    from: '',
    to: '',
    user_no: '',
    status: 'commissioned',
  });

  // Initializing History
  let history = useHistory();

  useEffect(() => {
    if (history.action !== 'POP') {
      handleReload('orders');
    }

    document.title = 'Orders - SPMWeb';
  }, []);

  // Handle Market Filter
  const filterMarket = (market) => {
    setOrders((prev) => ({
      ...prev,
      orders: data.filter((item) => item.market === market),
    }));
    history.push(`${window.location.pathname}?page=1`);
  };

  // Render
  return (
    // Main
    <section className="main">
      {/* Main Heading */}
      <h2>Orders</h2>

      {/* Main Container */}
      <div className="main-container">
        {/* Main Container Head */}
        <div className="main-container-head">
          <div>
            <h3 style={{ textTransform: 'capitalize' }}>
              {status === 'all' ? 'All Orders' : status}
            </h3>
            &nbsp;-&nbsp;
            <span>(Total {orders.length})</span>
            {/* Quick Action Button */}
            {status === 'refunded' && role === 'admin' ? (
              <button
                className="green-btn"
                style={{ marginLeft: '30px' }}
                onClick={() => setTakingAction(!takingAction)}
              >
                {takingAction ? 'Cancel' : 'Quick Action'}
              </button>
            ) : null}
          </div>

          {/* Search */}
          <Search
            collection="orders"
            field="order_no"
            status={status}
            handleReload={handleReload}
            setData={setOrders}
          />
        </div>

        {/* Quick Action */}
        {takingAction && role === 'admin' ? (
          <form
            className="form-container orders-action-container"
            style={{
              padding: '20px 10px',
              borderBottom: '1px solid #e1e5eb',
            }}
            onSubmit={(e) =>
              orderActionSubmit(
                e,
                actions,
                setActions,
                setTakingAction,
                handleReload,
              )
            }
          >
            <section>
              <h3 style={{ margin: 'auto' }} className="action-heading">
                Select a date range to change status of orders
              </h3>
              <div className="form-input-container">
                <div>
                  <label htmlFor="from">From</label>
                  <DatePicker
                    required
                    selected={actions.from}
                    placeholderText="MM/DD/YYYY"
                    id="from"
                    onChange={(e) => orderActionInput(e, setActions, 'from')}
                  />
                </div>
                <div>
                  <label htmlFor="to">To</label>
                  <DatePicker
                    required
                    selected={actions.to}
                    placeholderText="MM/DD/YYYY"
                    id="to"
                    onChange={(e) => orderActionInput(e, setActions, 'to')}
                  />
                </div>
              </div>
              <div className="form-input-container">
                <input
                  type="number"
                  name="user_no"
                  placeholder="User ID (Optional)"
                  value={actions.user_no}
                  onChange={(e) => orderActionInput(e, setActions)}
                />
                <div className="form-options">
                  <label htmlFor="action_status">Status:</label>
                  <select
                    name="status"
                    id="action_status"
                    required
                    onChange={(e) => orderActionInput(e, setActions)}
                  >
                    <option value="commissioned" selected>
                      Commissioned
                    </option>
                    <option value="completed">Completed</option>
                  </select>
                </div>
              </div>
              <button type="submit" className="form-submit-btn">
                Change Status
              </button>
            </section>
          </form>
        ) : null}

        {/* Table */}
        <div className="table-container">
          <table className="main-table">
            {/* Table Head */}
            <thead>
              <tr>
                <th>#</th>
                <th>User</th>
                <th>Order Number</th>
                <th>Product</th>
                <th>Customer Email</th>
                <th>Market</th>
                <th>
                  {status === 'all' || status === 'ordered'
                    ? 'Create Date'
                    : 'Update Date'}
                </th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>

            {/* Table Body */}
            <tbody>
              {data.map((item, index) => {
                return (
                  <tr className="orders-row" key={index}>
                    <td>{item.order_no}</td>
                    <td>
                      <Link
                        to={
                          role !== 'user'
                            ? `/profile/${item.user_no}`
                            : '/profile'
                        }
                        className="link"
                      >
                        {item.user_name}
                      </Link>
                    </td>
                    <td>{item.order_number}</td>
                    <td>
                      <Link to={`/product/${item.product_no}`}>
                        <img src={item.pic} alt="Product pic" />
                      </Link>
                    </td>
                    <td>{item.customer_email}</td>
                    <td
                      className="link"
                      onClick={() => filterMarket(item.market)}
                    >
                      {item.market}
                    </td>
                    <td>
                      {status === 'all' || status === 'ordered'
                        ? item.dateCreated
                        : item.dateUpdated}
                    </td>
                    <td>{item.status}</td>

                    {/* View Button */}
                    <td>
                      <Link to={`/order/${item.order_no}`}>
                        <button className="green-btn">
                          <i className="fas fa-eye"></i>
                          &nbsp;View
                        </button>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <Pagination data={orders} setData={setData} />
      </div>
    </section>
  );
}

// Export
export default Orders;
