import { toast } from "react-toastify";

// Copy to Clipboard function
// This function will run if user clicks on heading or data on view component
const copyProduct = (product) => {
  // Making Clipboard
  const clipboard = `Keyword: \n   ${product.keyword} \n\nSold By: \n   ${product.amz_seller} \n\nBrand Name: \n   ${product.brand_name}`;

  // Clipboard Function
  navigator.clipboard.writeText(clipboard).then(
    function () {
      console.log("Async: Copying to clipboard was successful!");
      toast.success("Text copied to your clipboard.");
    },
    function (err) {
      toast.error("Could not copy text.");
      console.error("Async: Could not copy text: ", err);
    }
  );
};

// Order Clipboard Function
const copyOrder = (order) => {
  // Making Clipboard
  const clipboard = `Order Number: \n   ${order.order_number} \n\nCustomer Email: \n   ${order.customer_email}`;

  // Clipboard Function
  navigator.clipboard.writeText(clipboard).then(
    function () {
      toast.success("Text copied to your clipboard.");
      console.log("Async: Copying to clipboard was successful!");
    },
    function (err) {
      toast.error("Could not copy text.");
      console.error("Async: Could not copy text: ", err);
    }
  );
};

// Export
export { copyProduct, copyOrder };
