// Init
import Chart from "chart.js";

// Function
function chart(statistics, data) {
	let labels = [];
	let currentMonth = [];
	let lastMonth = [];

	// Filtering Current Month
	const currentMonthFilter = data.filter((item) => {
		const date = new Date();
		date.setMonth(date.getMonth() - 1);
		date.setFullYear(date.getFullYear() - 1);

		return (
			item.month === date.getMonth() && item.year >= date.getFullYear()
		);
	});

	// Filtering Last Month
	const lastMonthFilter = data.filter((item) => {
		const date = new Date();
		date.setMonth(date.getMonth() - 2);
		date.setFullYear(date.getFullYear() - 1);

		return (
			item.month === date.getMonth() && item.year >= date.getFullYear()
		);
	});

	// Making dataset by filtering data
	for (var i = 0; i < 30; i++) {
		// Pushing labels
		labels.push(i);

		// Pushing current month data
		currentMonth.push(
			currentMonthFilter.filter((item) => item.day === i).length
		);

		// Pushing last month data
		lastMonth.push(lastMonthFilter.filter((item) => item.day === i).length);
	}

	// Line Chart
	let ctx = document.getElementById("lineChart");
	new Chart(ctx, {
		type: "line",
		data: {
			labels: labels,
			datasets: [
				{
					label: ["Current Month"],
					data: currentMonth,
					backgroundColor: "rgba(54, 162, 235, 0.2)",
					borderColor: "rgba(54, 162, 235, 1)",
					borderWidth: 2,
					pointRadius: 0,
					hitRadius: 10,
					hoverRadius: 10,
				},
				{
					label: ["Last Month"],
					data: lastMonth,
					backgroundColor: "rgba(255, 102, 153, 0.1)",
					borderColor: "rgba(255, 102, 153, 1)",
					borderDash: [5, 3],
					borderWidth: 1,
					pointRadius: 0,
					hitRadius: 10,
					hoverRadius: 10,
				},
			],
		},

		// Line Chart options
		options: {
			scales: {
				yAxes: [
					{
						ticks: {
							beginAtZero: true,
							callback: function (value, index, array) {
								return value < 1000
									? value
									: value / 1000 + "K";
							},
						},
					},
				],
				xAxes: [
					{
						gridLines: {
							display: false,
						},
						ticks: {
							autoSkip: true,
							maxTicksLimit: 6,
						},
					},
				],
			},
		},
	});

	// Pie Chart
	let pieChart = document.getElementById("pieChart");
	new Chart(pieChart, {
		type: "pie",
		data: {
			labels: ["Completed", "Cancelled"],
			datasets: [
				{
					data: [statistics.completed, statistics.cancelled],
					backgroundColor: ["green", "red"],
				},
			],
		},
	});
}

// Export
export default chart;
