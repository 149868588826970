// Init
import axios from "axios";
import { analytics } from "../firebase";
import { toast } from "react-toastify";

// Handle Input
function handleInput(e, setMessage) {
  setMessage((prev) => {
    return {
      ...prev,
      [e.target.name]: e.target.value,
    };
  });
}

// Handle Submit
function handleSubmit(e, message, setMessage, setCreating, handleReload) {
  e.preventDefault();

  // Creating message
  axios
    .post("/messages", message)
    .then(() => {
      // If all success
      toast.success("Issue created successfully.");
      setMessage({
        seller_id: "",
        subject: "",
        message: "",
      });
      setCreating(false);
      handleReload("messages");

      // Logging analytics
      analytics.logEvent("Issues Created");
    })
    // If error
    .catch((err) => {
      // If session expired then redirect lo login page
      if (err.response.status === 401) {
        window.location = "/login";
        toast.error(err.response.data);
      } else {
        toast.error("Something went wrong.");
      }
      console.log({ ...err });
    });
}

// Handle Update for reply
function handleUpdate(id, reply, user, setReplying, setTemp, handleReload) {
  // Updating Messages
  axios
    .put("/messages", {
      _id: id,
      reply: reply,
    })
    .then(() => {
      // After update
      setReplying(false);
      handleReload("messages");

      // Setting temp state to show reply on screen without fetching from db
      setTemp((prev) => {
        return [
          ...prev,
          {
            author: user.name,
            dateAdded: new Date().toLocaleString(),
            message: reply,
          },
        ];
      });
    })
    // If error
    .catch((err) => {
      // If session expired then redirect lo login page
      if (err.response.status === 401) {
        toast.error(err.response.data);
        window.location = "/login";
      }
      toast.error("Cannot reply due to some technical issue");
    });
}

// Handle Resolve
const handleResolve = (id, setLoading, handleReload, setReading, index) => {
  setLoading(index);

  // Updating message status
  axios
    .patch("/messages", { _id: id })
    .then(() => {
      // If success
      toast.success("Issue resolved successfully.");
      handleReload("messages");
      setReading(false);
      setLoading();

      // Logging analytics
      analytics.logEvent("Issues Resolved");
    })
    // If error
    .catch((err) => {
      // If session expired then redirect lo login page
      if (err.response.status === 401) {
        window.location = "/login";
        toast.error(err.response.data);
      } else {
        toast.error("Cannot resolve the issue due to an error");
      }
      setLoading();
    });
};

// Handle Read
const handleRead = (item, user, role, handleReload) => {
  if (role === "admin") {
    if (item.sender !== user.uid && item.seller_no === user.uid) {
      // Updating message unread status
      axios
        .patch("/messages/read", { _id: item._id })
        // If success
        .then(() => handleReload("messages"))
        // If error
        .catch((err) => {
          // If session expired then redirect lo login page
          if (err.response.status === 401) {
            window.location = "/login";
          }
          console.log(err);
        });
    }
  } else {
    // Checking if reader is not the author or last message
    if (item.sender !== user.uid) {
      // Updating message unread status
      axios
        .patch("/messages/read", { _id: item._id })
        // If success
        .then(() => handleReload("messages"))
        // If error
        .catch((err) => {
          // If session expired then redirect lo login page
          if (err.response.status === 401) {
            window.location = "/login";
          }
          console.log(err);
        });
    }
  }
};

// Export
export { handleInput, handleSubmit, handleUpdate, handleResolve, handleRead };
