// Init
import axios from "axios";
import { analytics } from "../firebase";
import { toast } from "react-toastify";

// Handle Submit
const handleSubmit = (e, input, setInput, setLoading) => {
  e.preventDefault();
  setLoading(true);

  // Spreading input
  const { oldPassword, password, confirmPassword } = input;

  // Validation
  if (!oldPassword || !password || !confirmPassword) {
    // If field empty
    toast.error("All fields are required");
    setLoading(false);
  } else if (password !== confirmPassword) {
    // if password not matched
    toast.error("Password does not matched!");
    setLoading(false);
  } else if (password.length < 8) {
    // if password weak
    toast.error("Password must be at least 8 characters!");
    setLoading(false);
  } else {
    // If Validation Ok
    axios
      .patch("/users", input)
      .then(() => {
        toast.success("Password updated.");
        setLoading(false);
        setInput({
          oldPassword: "",
          password: "",
          confirmPassword: "",
        });

        // Logging analytics
        analytics.logEvent("Passwords Changed");
      })
      .catch((err) => {
        setLoading(false);
        // If session expired then redirect lo login page
        if (err.response.status === 401) {
          window.location = "/login";
          toast.error(err.response.data);
        } else if (err.response.status === 422) {
          toast.error("Old password is incorrect");
        } else {
          toast.error("Something went wrong.");
          console.log(err);
        }
      });
  }
};

// Export
export default handleSubmit;
