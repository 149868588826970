import React, { useState } from "react";

function MarketSearch({ setInput }) {
	// Initializing States
	let [market, setMarket] = useState("");
	let [market_cat, setMarket_cat] = useState("");

	// Handle Change
	const handleChange = (e) => {
		if (e.target.name === "market_cat") {
			setMarket_cat(e.target.value);
			setInput((prev) => {
				return {
					...prev,
					query: market + e.target.value,
				};
			});
		} else {
			setMarket(e.target.value);
			setInput((prev) => {
				return {
					...prev,
					query: e.target.value + market_cat,
				};
			});
		}
	};

	// Render
	return (
		<div className="market-search">
			<select name="market" onChange={handleChange}>
				<option value="" selected>
					Select Market
				</option>
				<option value="all">All</option>
				<option value="ESBP">ESBP</option>
				<option value="DEBP">DEBP</option>
				<option value="DE">DE</option>
				<option value="FR">FR</option>
				<option value="ITBP">ITBP</option>
				<option value="IT">IT</option>
				<option value="UKBP">UKBP</option>
				<option value="UK">UK</option>
				<option value="US">US</option>
				<option value="USBP">USBP</option>
				<option value="CABP">CABP</option>
				<option value="ES">ES</option>
				<option value="FRBP">FRBP</option>
				<option value="CA">CA</option>
				<option value="AUS">AUS</option>
				<option value="UAE">UAE</option>
				<option value="JP">JP</option>
				<option value="Russia">Russia</option>
				<option value="Netherland">Netherland</option>
				<option value="SA">SA</option>
				<option value="MX">MX</option>
			</select>
			<select name="market_cat" onChange={handleChange}>
				<option value="" selected>
					Review
				</option>
				<option value=" - No Review">No Review</option>
				<option value=" - Rating">Rating</option>
				<option value=" - High Commission">High Commission</option>
				<option value=" - Feedback">Feedback</option>
			</select>
		</div>
	);
}

export default MarketSearch;
