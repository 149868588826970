import React, { useState, useEffect } from "react";

function MarketDropdown({ product, setProduct }) {
	// Initializing States
	let [market, setMarket] = useState("");
	let [market_cat, setMarket_cat] = useState("");

	// Setting market to states we got from product
	useEffect(() => {
		setMarket(product.market.split(" - ")[0]);
		setMarket_cat(
			product.market.split(" - ")[1]
				? " - " + product.market.split(" - ")[1]
				: ""
		);
	}, []);

	// Handle Change
	const handleChange = (e) => {
		if (e.target.name === "market_cat") {
			setMarket_cat(e.target.value);
			setProduct((prev) => {
				return {
					...prev,
					market: market + e.target.value,
				};
			});
		} else {
			setMarket(e.target.value);
			setProduct((prev) => {
				return {
					...prev,
					market: e.target.value + market_cat,
				};
			});
		}
	};

	// Render
	return (
		<div className="form-options">
			<select
				required
				name="market"
				onChange={handleChange}
				style={{ margin: "0" }}
			>
				<option value="">Select Market</option>
				<option value="all" selected={market === "all"}>
					All
				</option>
				<option value="ESBP" selected={market === "ESBP"}>
					ESBP
				</option>
				<option value="DEBP" selected={market === "DEBP"}>
					DEBP
				</option>
				<option value="DE" selected={market === "DE"}>
					DE
				</option>
				<option value="FR" selected={market === "FR"}>
					FR
				</option>
				<option value="ITBP" selected={market === "ITBP"}>
					ITBP
				</option>
				<option value="IT" selected={market === "IT"}>
					IT
				</option>
				<option value="UKBP" selected={market === "UKBP"}>
					UKBP
				</option>
				<option value="UK" selected={market === "UK"}>
					UK
				</option>
				<option value="US" selected={market === "US"}>
					US
				</option>
				<option value="USBP" selected={market === "USBP"}>
					USBP
				</option>
				<option value="CABP" selected={market === "CABP"}>
					CABP
				</option>
				<option value="ES" selected={market === "ES"}>
					ES
				</option>
				<option value="FRBP" selected={market === "FRBP"}>
					FRBP
				</option>
				<option value="CA" selected={market === "CA"}>
					CA
				</option>
				<option value="AUS" selected={market === "AUS"}>
					AUS
				</option>
				<option value="UAE" selected={market === "UAE"}>
					UAE
				</option>
				<option value="JP" selected={market === "JP"}>
					JP
				</option>
				<option value="Russia" selected={market === "Russia"}>
					Russia
				</option>
				<option value="Netherland" selected={market === "Netherland"}>
					Netherland
				</option>
				<option value="SA" selected={market === "SA"}>
					SA
				</option>
				<option value="MX" selected={market === "MX"}>
					MX
				</option>
			</select>
			<select
				name="market_cat"
				onChange={handleChange}
				style={{ marginRight: "0" }}
			>
				<option value="">Review</option>
				<option
					value=" - No Review"
					selected={market_cat === " - No Review"}
				>
					No Review
				</option>
				<option value=" - Rating" selected={market_cat === " - Rating"}>
					Rating
				</option>
				<option
					value=" - High Commission"
					selected={market_cat === " - High Commission"}
				>
					High Commission
				</option>
				<option
					value=" - Feedback"
					selected={market_cat === " - Feedback"}
				>
					Feedback
				</option>
			</select>
		</div>
	);
}

export default MarketDropdown;
