// We call this function to fetch data from database
// Init
import axios from "axios";

// Getting data from databse
const fetch = (setData, collection) => {
	if (collection === "all") {
		// Getting Data
		axios
			.get("/all")
			.then((res) => {
				// Setting State
				setData((prev) => {
					return {
						...prev,
						...res.data,
					};
				});
			})
			.catch(() => (window.location = "/login"));
	} else {
		// Getting Data
		axios
			.get(`/${collection}`)
			.then((res) => {
				// Setting State
				setData((prev) => {
					return {
						...prev,
						[collection]: res.data,
					};
				});
			})
			.catch(() => (window.location = "/login"));
	}
};

// Export
export default fetch;
