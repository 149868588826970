// Init
import axios from "axios";
import { toast } from "react-toastify";

// Handle Status
const handleStatus = (id, limit, active, setData, index, setLoading) => {
  setLoading(index);

  if (limit > 0 || active) {
    axios
      .patch("/products", {
        _id: id,
        active: !active,
      })
      .then(() => {
        setLoading();
        toast.success("Product status updated");

        // Deleting the Product from store
        setData((prev) => {
          return prev.filter((product) => product._id !== id);
        });
      })
      .catch((err) => {
        // If session expired then redirect lo login page
        if (err.response.status === 401) {
          window.location = "/login";
          toast.error(err.response.data);
        } else if (err.response.status === 403) {
          toast.error(err.response.data);
        } else {
          toast.error("Something went wrong.");
        }
        console.log(err);
        setLoading();
      });
  } else {
    setLoading();
    toast.error("Please update today limit to enable the product");
  }
};

// Handle reserve
const handleReserve = (id, handleReload, index, setLoading) => {
  setLoading(index);
  // Fetching products to check remaining limit
  axios
    .post("/reservations", { product_id: id })
    .then(() => {
      // Done
      toast.success("Product reserved successfully.");
      setLoading();
      handleReload("products");
    })
    .catch((err) => {
      // Error
      setLoading();
      // If session expired then redirect lo login page
      if (err.response.status === 401) {
        toast.error(err.response.data);
        window.location = "/login";
      } else if (err.response.status === 400 || err.response.status === 403) {
        toast.error(err.response.data);
      } else {
        toast.error("Something went wrong.");
      }
    });
};

// Export
export { handleStatus, handleReserve };
