// Firebase
import axios from 'axios';
import { analytics } from '../../firebase';
import { toast } from 'react-toastify';

// handle input
const handleInput = (e, setLogin) => {
  setLogin((prev) => {
    return {
      ...prev,
      [e.target.name]: e.target.value,
    };
  });
};

// Handle Submit
const handleSubmit = (e, setLoading, login) => {
  e.preventDefault();
  setLoading(true);

  // Logging in
  axios
    .post('/auth/login', login)
    .then(() => {
      // if logged in
      setLoading(false);
      window.location = '/dashboard';

      // Logging analytics
      analytics.logEvent('Login');
    })
    .catch((err) => {
      // If error
      setLoading(false);
      if (err.response.status && err.response.status === 401) {
        toast.error(err.response.data);
      } else {
        toast.error('Something went wrong.');
        console.log({ ...err });
      }
    });
};

// Export
export { handleInput, handleSubmit };
