import React, { useEffect } from "react";

function PicModel({ model, setModel }) {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="image-model">
			<i
				className="fas fa-times"
				onClick={() => setModel({ display: false, src: "" })}
			></i>
			<img src={model.src} alt="" />
		</div>
	);
}

export default PicModel;
