// Init
import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import fetch from "../api/fetch/fetch";

// utils
import filter from "../utils/filter";

// hoc
import UsersProfile from "../hoc/UsersProfile.jsx";

// Components
import Sidebar from "../components/Sidebar.jsx";
import Footer from "../components/Footer.jsx";

// Containers
import Dashboard from "../containers/Dashboard.jsx";
import Orders from "../containers/Orders.jsx";
import Order from "../containers/Order.jsx";
import AddProduct from "../containers/AddProduct.jsx";
import Products from "../containers/Products.jsx";
import Product from "../containers/Product.jsx";
import Reservations from "../containers/Reservations.jsx";
import Users from "../containers/Users.jsx";
import AddUser from "../containers/AddUser.jsx";
import AddInvoice from "../containers/AddInvoice.jsx";
import Invoices from "../containers/Invoices.jsx";
import Invoice from "../containers/Invoice.jsx";
import Accounts from "../containers/Accounts.jsx";
import Profile from "../containers/Profile.jsx";
import Password from "../containers/Password.jsx";
import Excel from "../containers/Excel.jsx";
import Support from "../containers/Support.jsx";
import ErrorPage from "../containers/ErrorPage.jsx";

function Routes({ role, user }) {
  // Initializing State
  let [data, setData] = useState({
    user: user,
    users: [],
    products: {
      // Setting search property here because if user search on product page and view and go back then this property does not reset
      searching: false,
      docs: [],
    },
    reservations: [],
    orders: [],
    datasets: [],
    dashboard: {},
    accounts: [],
    invoices: [],
    messages: [],
  });
  let [reload, setReload] = useState(1); //We are tracking this in useEffect hook so if this change the component will reload
  let [collectionToLoad, setCollectionToLoad] = useState("all"); //Fetch function will check what to fetch

  // Getting Data from db
  useEffect(() => {
    fetch(setData, collectionToLoad);
  }, [reload]);

  // Handle Reload function
  const handleReload = (collection) => {
    setCollectionToLoad(collection);
    setReload((prev) => ++prev);
  };

  // Rendering
  return (
    <>
      <Sidebar
        role={role}
        user={data.user}
        messages={filter(data.messages, "unread", true)}
      />
      {/* All Routes */}
      <Switch>
        <Route
          exact
          path="/dashboard"
          render={() => (
            <Dashboard datasets={data.datasets} dashboard={data.dashboard} />
          )}
        />

        {/* 
				===========================
					Order Routes
				===========================
				*/}

        <Route
          exact
          path="/all_orders"
          render={() => (
            <Orders
              status="all"
              user={data.user}
              orders={data.orders}
              setOrders={setData}
              handleReload={handleReload}
              role={role}
            />
          )}
        />
        <Route
          exact
          path="/orders"
          render={() => (
            <Orders
              status="ordered"
              user={data.user}
              orders={filter(data.orders, "status", "ordered")}
              setOrders={setData}
              handleReload={handleReload}
              role={role}
            />
          )}
        />
        <Route
          exact
          path="/review"
          render={() => (
            <Orders
              status="reviewed"
              user={data.user}
              orders={filter(data.orders, "status", "reviewed")}
              setOrders={setData}
              handleReload={handleReload}
              role={role}
            />
          )}
        />
        <Route
          exact
          path="/delivered"
          render={() => (
            <Orders
              status="delivered"
              user={data.user}
              orders={filter(data.orders, "status", "delivered")}
              setOrders={setData}
              handleReload={handleReload}
              role={role}
            />
          )}
        />
        <Route
          exact
          path="/review_deleted"
          render={() => (
            <Orders
              status="review deleted"
              user={data.user}
              orders={filter(data.orders, "status", "review deleted")}
              setOrders={setData}
              handleReload={handleReload}
              role={role}
            />
          )}
        />
        <Route
          exact
          path="/refunded"
          render={() => (
            <Orders
              status="refunded"
              user={data.user}
              orders={filter(data.orders, "status", "refunded")}
              setOrders={setData}
              handleReload={handleReload}
              role={role}
            />
          )}
        />
        <Route
          exact
          path="/onhold"
          render={() => (
            <Orders
              status="on hold"
              user={data.user}
              orders={filter(data.orders, "status", "on hold")}
              setOrders={setData}
              handleReload={handleReload}
              role={role}
            />
          )}
        />
        <Route
          exact
          path="/pending"
          render={() => (
            <Orders
              status="pending"
              user={data.user}
              orders={filter(data.orders, "status", "pending")}
              setOrders={setData}
              handleReload={handleReload}
              role={role}
            />
          )}
        />
        <Route
          exact
          path="/cancelled"
          render={() => (
            <Orders
              status="cancelled"
              user={data.user}
              orders={filter(data.orders, "status", "cancelled")}
              setOrders={setData}
              handleReload={handleReload}
              role={role}
            />
          )}
        />
        <Route
          exact
          path="/commissioned"
          render={() => (
            <Orders
              status="commissioned"
              user={data.user}
              orders={filter(data.orders, "status", "commissioned")}
              setOrders={setData}
              handleReload={handleReload}
              role={role}
            />
          )}
        />
        <Route
          exact
          path="/completed"
          render={() => (
            <Orders
              status="completed"
              user={data.user}
              orders={filter(data.orders, "status", "completed")}
              setOrders={setData}
              handleReload={handleReload}
              role={role}
            />
          )}
        />
        <Route
          exact
          path="/order/:orderNo"
          render={() => (
            <Order
              role={role}
              user={data.user}
              orders={data.orders}
              handleReload={handleReload}
            />
          )}
        />

        {/*
				=========================
					Product Routes
				=========================
				*/}
        {role === "admin" || role === "manager" ? (
          <Route exact path="/add_product" render={() => <AddProduct />} />
        ) : null}

        <Route
          exact
          path="/products"
          render={() => (
            <Products
              enabled={true}
              products={filter(data.products.docs, "active", true)}
              setProducts={setData}
              role={role}
              searching={data.products.searching}
            />
          )}
        />

        {role === "admin" || role === "manager" ? (
          <Route
            exact
            path="/disabled_products"
            render={() => (
              <Products
                products={filter(data.products.docs, "active", false)}
                enabled={false}
                setProducts={setData}
                searching={data.products.searching}
              />
            )}
          />
        ) : null}

        <Route
          exact
          path="/products/:category"
          render={() => (
            <Products
              enabled={true}
              products={filter(data.products.docs, "active", true)}
              setProducts={setData}
              role={role}
              searching={data.products.searching}
            />
          )}
        />

        <Route
          exact
          path="/product/:productNo"
          render={() => <Product role={role} />}
        />

        {/* 
				=============================
					Reservation Routes
				=============================
				 */}

        <Route
          exact
          path="/reservations"
          render={() => (
            <Reservations
              role={role}
              reservations={data.reservations}
              handleReload={handleReload}
            />
          )}
        />

        {/* 
				========================
					User Routes
				========================
				  */}

        {role === "admin" && (
          <Route
            exact
            path="/users"
            render={() => (
              <Users
                status="enabled"
                handleReload={handleReload}
                users={filter(data.users, "status", "enabled")}
                setUsers={setData}
              />
            )}
          />
        )}

        {role === "admin" && (
          <Route
            exact
            path="/disabled_users"
            render={() => (
              <Users
                handleReload={handleReload}
                users={filter(data.users, "status", "disabled")}
                setUsers={setData}
                status="disabled"
              />
            )}
          />
        )}

        {role === "admin" && (
          <Route
            exact
            path="/pending_users"
            render={() => (
              <Users
                handleReload={handleReload}
                users={filter(data.users, "status", "pending")}
                setUsers={setData}
                status="pending"
              />
            )}
          />
        )}

        {role === "admin" && (
          <Route exact path="/add-user" render={() => <AddUser />} />
        )}

        <Route
          exact
          path="/profile"
          render={() => (
            <Profile
              handleReload={handleReload}
              orders={data.orders}
              user={data.user}
              restricted={false}
              role={role}
            />
          )}
        />

        {role === "admin" || role === "manager" ? (
          <Route
            exact
            path="/profile/:userNo"
            render={() => (
              <UsersProfile
                users={data.users}
                orders={data.orders}
                role={role}
                handleReload={handleReload}
              />
            )}
          />
        ) : null}

        <Route
          exact
          path="/user/password"
          render={() => <Password user={data.user} />}
        />

        {/* 
				=============================
					Payment Routes
				=============================
				 */}

        {role === "manager" ? (
          <Route
            exact
            path="/create_invoice"
            render={() => (
              <AddInvoice
                accounts={data.accounts}
                handleReload={handleReload}
              />
            )}
          />
        ) : null}

        {role !== "user" ? (
          <Route
            exact
            path="/invoices"
            render={() => (
              <Invoices
                invoices={filter(data.invoices, "active", true)}
                active={true}
                role={role}
                setInvoices={setData}
                handleReload={handleReload}
              />
            )}
          />
        ) : null}

        {role !== "user" ? (
          <Route
            exact
            path="/paid_invoices"
            render={() => (
              <Invoices
                invoices={filter(data.invoices, "active", false)}
                active={false}
                role={role}
                setInvoices={setData}
                handleReload={handleReload}
              />
            )}
          />
        ) : null}

        {role !== "user" ? (
          <Route
            exact
            path="/invoice/:invoiceNo"
            render={() => (
              <Invoice
                invoices={data.invoices}
                accounts={data.accounts}
                handleReload={handleReload}
                role={role}
              />
            )}
          />
        ) : null}

        {role === "admin" ? (
          <Route
            exact
            path="/payment_accounts"
            render={() => (
              <Accounts accounts={data.accounts} handleReload={handleReload} />
            )}
          />
        ) : null}

        {/* 
				=============================
					Support & Excel Routes
				=============================
				 */}

        <Route
          exact
          path="/excel"
          render={() => (
            <Excel
              orders={data.orders}
              users={data.users}
              accounts={data.accounts}
              handleReload={handleReload}
              role={role}
            />
          )}
        />

        <Route
          exact
          path="/support"
          render={() => (
            <Support
              role={role}
              active={true}
              user={data.user}
              messages={filter(data.messages, "active", true)}
              handleReload={handleReload}
            />
          )}
        />

        <Route
          exact
          path="/support/resolved"
          render={() => (
            <Support
              role={role}
              active={false}
              user={data.user}
              messages={filter(data.messages, "active", false)}
              handleReload={handleReload}
            />
          )}
        />

        {/* Error */}
        <Route component={ErrorPage} />
      </Switch>

      {/* Footer */}
      <Footer />
    </>
  );
}

// Export
export default Routes;
