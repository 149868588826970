// Init
import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

// utils
import Search from '../components/Search';
import { handleStatus, handleReserve } from '../utils/products';

// Pagination
import ProductsPagination from '../components/ProductsPagination.jsx';
import Pagination from '../components/Pagination.jsx';

// Component
function Products({ enabled, products, setProducts, role, searching }) {
  // Initializing States
  let [loading, setLoading] = useState();
  let [data, setData] = useState([]);
  let [productsLength, setProductsLength] = useState(0);
  let [reload, setReload] = useState(1);

  // Initializing History
  let history = useHistory();
  let { category } = useParams();

  // Updating component to check latest data
  useEffect(() => {
    if (history.action !== 'POP') {
      setProducts((prev) => ({
        ...prev,
        products: { searching: false, docs: prev.products.docs },
      }));
    }
    // Changing the title of page
    document.title = 'Products - SPMWeb';
  }, [enabled]);

  // Setting products length to show on table's head
  useEffect(() => {
    if (searching) {
      setProductsLength(products.length);
    }
  }, [products]);

  // Handle Seller Filter
  const filterSeller = (seller_no) => {
    setProducts((prev) => ({
      ...prev,
      products: {
        searching: true,
        docs: data.filter((item) => item.seller_no === seller_no),
      },
    }));
    history.push(`${window.location.pathname}?page=1`);
  };

  // Handle Market Filter
  const filterMarket = (market) => {
    setProducts((prev) => ({
      ...prev,
      products: {
        searching: true,
        docs: data.filter((item) => item.market === market),
      },
    }));
    history.push(`${window.location.pathname}?page=1`);
  };

  // Products Handle reload
  const handleReload = () => {
    setReload((prev) => ++prev);
    setProducts((prev) => ({
      ...prev,
      products: { searching: false, docs: prev.products.docs },
    }));
  };

  // Render
  return (
    // Main
    <section className="main">
      {/* Main Heading */}
      <h2>Products</h2>

      {/* Main Container */}
      <div className="main-container">
        {/* Main Container Head */}
        <div className="main-container-head">
          <div>
            <h3>{enabled ? 'Active' : 'Disabled'} Products</h3>
            &nbsp;-&nbsp;
            <span>(Total {productsLength})</span>
          </div>

          {/* Search */}
          <Search
            collection="products"
            category={category}
            field="keyword"
            setData={setProducts}
            status={enabled}
            handleReload={handleReload}
          />
        </div>

        {/* Table */}
        <div className="table-container">
          <table className="main-table">
            {/* Table Head */}
            <thead>
              <tr>
                <th>Seller Name</th>
                <th>Market</th>
                <th>Sale Limit</th>
                <th>Today Remaining</th>
                <th>Total Remaining</th>
                <th>Commission</th>
                <th>Keyword</th>
                <th>Product ID</th>
                <th>Image</th>
                <th></th>
                <th></th>
              </tr>
            </thead>

            {/* Table Body */}
            <tbody>
              {data.map((product, index) => {
                return (
                  <tr className="products-row" key={index}>
                    <td
                      className="link"
                      onClick={() => filterSeller(product.seller_no)}
                    >
                      {product.seller_no}
                    </td>
                    <td
                      className="link"
                      onClick={() => filterMarket(product.market)}
                    >
                      {product.market}
                    </td>
                    <td>{product.sale_limit}</td>
                    <td>{product.today_remaining}</td>
                    <td>{product.remaining}</td>
                    <td>{product.commission}</td>
                    <td>{product.keyword.substring(0, 15) + '...'}</td>
                    <td>{product.product_no}</td>

                    {/* Image */}
                    <td>
                      <img src={product.pic} alt="Product Pic" />
                    </td>

                    {/* View Button */}
                    <td>
                      <Link to={`/product/${product.product_no}`}>
                        <button className="green-btn">
                          <i className="fas fa-eye"></i>
                          &nbsp;View
                        </button>
                      </Link>
                    </td>

                    {/* Enabled, Disabled and reserve button */}
                    <td style={{ textAlign: 'left' }}>
                      {role === 'user' ? (
                        <button
                          className="form-submit-btn"
                          disabled={loading === index}
                          onClick={() =>
                            handleReserve(
                              product._id,
                              handleReload,
                              index,
                              setLoading,
                            )
                          }
                        >
                          {loading === index && (
                            <i className="fa fa-refresh fa-spin" />
                          )}
                          &nbsp; Reserve
                        </button>
                      ) : enabled ? (
                        <button
                          className="del-btn"
                          disabled={loading === index}
                          onClick={() =>
                            handleStatus(
                              product._id,
                              product.today_remaining,
                              product.active,
                              setData,
                              index,
                              setLoading,
                            )
                          }
                        >
                          {loading === index && (
                            <i className="fa fa-refresh fa-spin" />
                          )}
                          &nbsp; Disable
                        </button>
                      ) : (
                        <button
                          className="enabled-btn"
                          disabled={loading === index}
                          onClick={() =>
                            handleStatus(
                              product._id,
                              product.today_remaining,
                              product.active,
                              setData,
                              index,
                              setLoading,
                            )
                          }
                        >
                          {loading === index && (
                            <i className="fa fa-refresh fa-spin" />
                          )}
                          &nbsp; Enable
                        </button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        {searching ? (
          <Pagination data={products} setData={setData} />
        ) : (
          <ProductsPagination
            setData={setData}
            status={enabled}
            reload={reload}
            setProductsLength={setProductsLength}
            category={category}
          />
        )}
      </div>
    </section>
  );
}

// Export
export default Products;
