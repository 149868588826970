// Order Date Range
function orderDateRange(dateUpdated, input) {
	// Making from date format to compare
	const from = [
		input.from.getMonth() + 1,
		input.from.getDate(),
		input.from.getFullYear(),
	];
	const fromYear = from[2];
	const fromMonth = from[0] < 10 ? 0 + "" + from[0] : from[0];
	const fromDay = from[1] < 10 ? 0 + "" + from[1] : from[1];
	const fromFullDate = [fromYear, fromMonth, fromDay].join("");

	// Making to date format to compare
	const to = [
		input.to.getMonth() + 1,
		input.to.getDate(),
		input.to.getFullYear(),
	];
	const toYear = to[2];
	const toMonth = to[0] < 10 ? 0 + "" + to[0] : to[0];
	const toDay = to[1] < 10 ? 0 + "" + to[1] : to[1];
	const toFullDate = [toYear, toMonth, toDay].join("");

	// Making dateUpdated format to compare
	const date = dateUpdated.split(",")[0].split("/");
	const year = date[2];
	const month = date[0] < 10 ? 0 + "" + date[0] : date[0];
	const day = date[1] < 10 ? 0 + "" + date[1] : date[1];
	const OrderFullDate = [year, month, day].join("");

	// Result
	return (
		parseInt(OrderFullDate) >= parseInt(fromFullDate) &&
		parseInt(OrderFullDate) <= parseInt(toFullDate)
	);
}

// Invoice Date Range
function invoiceDateRange(transactionDate, input) {
	// Making from date format to compare
	const from = [
		input.from.getMonth() + 1,
		input.from.getDate(),
		input.from.getFullYear(),
	];
	const fromYear = from[2];
	const fromMonth = from[0] < 10 ? 0 + "" + from[0] : from[0];
	const fromDay = from[1] < 10 ? 0 + "" + from[1] : from[1];
	const fromFullDate = [fromYear, fromMonth, fromDay].join("");

	// Making to date format to compare
	const to = [
		input.to.getMonth() + 1,
		input.to.getDate(),
		input.to.getFullYear(),
	];
	const toYear = to[2];
	const toMonth = to[0] < 10 ? 0 + "" + to[0] : to[0];
	const toDay = to[1] < 10 ? 0 + "" + to[1] : to[1];
	const toFullDate = [toYear, toMonth, toDay].join("");

	// Making dateUpdated format to compare
	const fullTransactionDate = transactionDate.split("-").join("");

	// Result
	return (
		parseInt(fullTransactionDate) >= parseInt(fromFullDate) &&
		parseInt(fullTransactionDate) <= parseInt(toFullDate)
	);
}

// Export
export { orderDateRange, invoiceDateRange };
