// Init
import React, { useState, useEffect } from 'react';
import { analytics } from '../firebase';
import chart from '../utils/chart';

// Component
function Dashboard({ datasets, dashboard }) {
  // Initializing States
  let [statistics, setStatistics] = useState({});

  // Changing the title of page
  useEffect(() => {
    document.title = 'Dashboard - SPMWeb';

    // Logging analytics
    analytics.logEvent('Dashboard');
  }, []);
  // Creating statistics
  useEffect(() => {
    setStatistics(dashboard);
    console.log('Dashboard ---->', dashboard);
  }, [dashboard]);
  // Dataset for chart js
  useEffect(() => {
    chart(statistics, datasets);
  }, [datasets, statistics]);

  // Render
  return (
    <section className="dashboard">
      {/* Head */}
      <div className="dashboard-heading">
        <span>DASHBOARD</span>
        <h3>Orders Overview</h3>
      </div>

      {/* Body */}
      <div className="dashboard-body">
        {/* First row */}
        <div className="dashboard-first-row">
          <div className="dashboard-item">
            <span>COMPLETED</span>
            <h6>{statistics.completed}</h6>
          </div>
          <div className="dashboard-item">
            <span>CANCELLED</span>
            <h6>{statistics.cancelled}</h6>
          </div>
          <div className="dashboard-item">
            <span>REFUNDED</span>
            <h6>{statistics.refunded}</h6>
          </div>
          <div className="dashboard-item">
            <span>COMMISSIONED</span>
            <h6>{statistics.commissioned}</h6>
          </div>
        </div>

        {/* Second Row */}
        <div className="dashboard-second-row">
          <div className="dashboard-item">
            <span>REFUNDED PENDING REVIEW</span>
            <h6>{statistics.pending}</h6>
          </div>
          <div className="dashboard-item">
            <span>REVIEWED</span>
            <h6>{statistics.reviewed}</h6>
          </div>
          <div className="dashboard-item">
            <span>ORDERED</span>
            <h6>{statistics.ordered}</h6>
          </div>
        </div>

        {/* Third Row */}
        <div className="dashboard-third-row">
          <div className="dashboard-item">
            <span>REVIEW SUBMITTED FOR REFUND</span>
            <h6>{statistics.delivered}</h6>
          </div>
          <div className="dashboard-item">
            <span>REVIEW DELETED</span>
            <h6>{statistics.review_deleted}</h6>
          </div>
          <div className="dashboard-item">
            <span>ON HOLD</span>
            <h6>{statistics.on_hold}</h6>
          </div>
        </div>

        {/* fourth row with charts */}
        <div className="dashboard-fourth-row">
          <div className="dashboard-item chart">
            <h2>Completed Orders</h2>
            <canvas
              id="lineChart"
              style={{ width: '100%', height: '100%' }}
            ></canvas>
          </div>
          <div className="dashboard-item chart">
            <h2>Order By Status</h2>
            <canvas
              id="pieChart"
              style={{ width: '100%', height: '100%' }}
            ></canvas>
          </div>
        </div>
      </div>
    </section>
  );
}

// Export
export default Dashboard;
