// Init
import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";

// Component
function Pagination({ data, setData }) {
	// Getting Query Data
	let { pathname, search } = useLocation();
	let query = queryString.parse(search);
	let page = parseInt(query.page) - 1 || 0;
	let { market } = queryString.parse(search);

	let history = useHistory();

	// Initializing States
	const [perPage] = useState(20);
	const [pageCount, setPageCount] = useState(0);

	// Using Effect
	useEffect(() => {
		getData();

		// Scrolling to top everytime when page changes
		window.scrollTo(0, 0);
	}, [data, page]);

	// Handle Page Click
	const handlePageClick = (e) => {
		const selectedPage = e.selected;
		// If user is in public page then we need to reserve market query.
		if (window.location.pathname === "/") {
			history.push(
				`${pathname}?market=${market || ""}&page=${selectedPage + 1}`
			);
		} else {
			history.push(`${pathname}?page=${selectedPage + 1}`);
		}
	};

	// Get data function
	const getData = () => {
		const offset = page * perPage;
		var slice = data.slice(offset, offset + perPage);
		setData(slice);

		setPageCount(Math.ceil(data.length / perPage));
	};

	// Render
	return (
		<ReactPaginate
			previousLabel={"<"}
			nextLabel={">"}
			breakLabel={"..."}
			breakClassName={"break-me"}
			pageCount={pageCount}
			marginPagesDisplayed={2}
			pageRangeDisplayed={5}
			onPageChange={handlePageClick}
			containerClassName={"pagination"}
			subContainerClassName={"pages pagination"}
			activeClassName={"active"}
			forcePage={page}
		/>
	);
}

// Export
export default Pagination;
